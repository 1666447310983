import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import { getFormattedPrice } from "../../utils/getFormattedPrice";

const AppointmentDetails = () => {
  const options = ["COMPLETED", "CANCELLED", "PLACED"];
  const [loading, setLoading] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [appointmentStatus, setAppointmentStatus] = useState(null);
  const { id } = useParams();

  const getAppointmentDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/appointment/${id}`);
      response.status === 200 && setAppointment(response.data.appointment);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const updateAppointmentStatus = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `/api/appointment/${id}/appointment-status`,
        {
          status: appointmentStatus,
        }
      );
      if (response.status === 200) {
        setUpdateSuccess(true);
        setAppointment(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAppointmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  useEffect(() => {
    if (appointmentStatus) updateAppointmentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentStatus]);

  if (loading) return <LoaderComp />;
  return (
    appointment && (
      <>
        <Container>
          <h1>Appointment Details</h1>
          <div>
            Details for Appointment ID:{" "}
            <strong>#{appointment?._id.toUpperCase()}</strong>
          </div>

          <section className="text-end">
            Appointment <i className="fa fa-arrow-right"></i> Appointment
            Details
          </section>

          <section className="appointmentDetailsWrapper">
            <Row>
              <section className="flex-between mb-3">
                <div>
                  Booked On: {moment(appointment?.createdAt).format("L")}
                  <div className="text-muted">
                    Appointment ID: {appointment?._id.toUpperCase()}
                  </div>
                </div>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setAppointmentStatus(e.target.value)}
                  >
                    <option>{appointment?.status}</option>
                    {options
                      .filter((i) => i !== appointment?.status)
                      .map((opt) => (
                        <option value={opt} key={opt}>
                          {opt}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </section>
              <hr />
            </Row>

            <Row>
              <Col md={4}>
                <section>
                  <h2>Customer Info</h2>
                  <ul>
                    <li>
                      Booked By: <strong>{appointment?.fullname}</strong>
                    </li>
                    <li>
                      Email: <strong>{appointment?.email}</strong>
                    </li>
                    <li>
                      Phone : <strong>{appointment?.phone}</strong>
                    </li>
                    <li>
                      Appointment Status: <strong>{appointment?.status}</strong>
                    </li>
                  </ul>
                </section>
              </Col>
              <Col md={4}>
                <section>
                  <h2>Timing</h2>
                  <ul>
                    <li>
                      Date{" "}
                      <strong>
                        {moment(appointment?.date).format("ddd, D-MMMM-yyyy")}
                      </strong>
                    </li>
                    <li>
                      Time: <strong>{appointment?.time}</strong>
                    </li>
                  </ul>
                </section>
              </Col>
              <Col md={4}>
                <section>
                  <h2>Location</h2>
                  <ul>
                    <li>{appointment?.location || "-"}</li>
                  </ul>
                </section>
              </Col>
            </Row>
          </section>

          <section className="AppointmentList">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Service</th>
                  <th>Price</th>
                  <th>Persons</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {appointment?.services?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div>
                        <span>{item.name}</span>
                      </div>
                    </td>
                    <td>{getFormattedPrice(item.price)}</td>
                    <td>{item?.quantity}</td>
                    <td>{getFormattedPrice(item.price * item.quantity)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <section className="order-footer">
              <div className="order-footer-main">
                <Row>
                  <Col md={6}>
                    <span className="text-end">Total</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>
                        {getFormattedPrice(appointment?.subTotal)}
                      </strong>
                    </span>
                  </Col>
                </Row>
              </div>
            </section>
          </section>

          <h1 className="text-center mt-4 cse">Track Appointment</h1>
          <section className="trackerWrapper">
            <div>
              <i
                className="fa fa-shopping-bag"
                style={{
                  background: appointment?.status === "PLACED" && "green",
                }}
              ></i>
              <div>Appointment Placed</div>
            </div>
            <div>
              <i
                className="fa fa-check-square-o"
                style={{
                  background: appointment?.status === "COMPLETED" && "green",
                }}
              ></i>
              <div>Appointment Completed</div>
            </div>
          </section>
        </Container>
      </>
    )
  );
};

export default AppointmentDetails;
