import React, { useState, useEffect } from "react";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";
import {
  createGalleryAsync,
  deleteGalleryAsync,
  getGalleryAsync,
  resetGallery,
} from "../../redux/gallerySlice";

const Gallery = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const gallery = useSelector((state) => state.gallery.gallery);
  const updateSuccess = useSelector((state) => state.gallery.updateSuccess);
  const loading = useSelector((state) => state.gallery.loading);

  //GALLERY STATES
  const [galleryImage, setGalleryImage] = useState([]);

  const [inValidImages, setInValidImages] = useState(false);

  const uploadGalleryImageHandler = async (e) => {
    e.preventDefault();
    for (const file of e.target.files) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post("/api/upload", formData, config);

        setGalleryImage((prev) => [...prev, data]);
      } catch (error) {
        //console.error(error);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(resetGallery());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    dispatch(getGalleryAsync());
  }, [dispatch]);

  const validateCraousel = async () => {
    if (!galleryImage || galleryImage.length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  const createGallery = async () => {
    const validate = await validateCraousel();
    if (!validate) {
      setInValidImages(true);
    } else {
      setInValidImages(false);
      const data = {
        images: galleryImage,
      };
      dispatch(createGalleryAsync(data));
      setGalleryImage([]);
      handleClose();
    }
  };

  if (loading) return <LoaderComp />;

  return (
    <div>
      <Container>
        <section>
          <h1>Gallery</h1>
        </section>

        <section className="SliderWrapper">
          <button className="bton bton--md bton--primary" onClick={handleShow}>
            Add Images
          </button>

          <Row>
            {gallery.map((c, i) => {
              return (
                <Col md={4} key={i}>
                  <section className="sliderHolder">
                    <figure className="sliderHolder-image">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PREFIX}${c.image}`}
                        alt=""
                      />
                    </figure>
                    <button
                      className="bton bton--danger"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteGalleryAsync(c._id));
                      }}
                    >
                      Delete
                    </button>
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Images to Gallery </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Form>
              <Row>
                <Col md={12}>
                  <section className="galleryWrapper">
                    <small>Info: you can upload multiple images)</small>
                    <div className="center">
                      <label>
                        <i className="fa fa-cloud-upload"></i>
                        <br />
                        <span>Click to upload Images</span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={uploadGalleryImageHandler}
                          multiple
                        />
                      </label>
                    </div>
                    {inValidImages && galleryImage.length === 0 && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Please upload atleast one image
                      </small>
                    )}
                    <Row>
                      {galleryImage &&
                        galleryImage.length > 0 &&
                        galleryImage.map((img, key) => (
                          <Col md={6} key={key}>
                            <figure className="preview">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PREFIX}${img}`}
                                alt=""
                              />
                            </figure>
                          </Col>
                        ))}
                    </Row>
                  </section>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button className="bton bton--danger" onClick={handleClose}>
            Close
          </button>
          <button
            className="bton bton--primary"
            onClick={(e) => {
              e.preventDefault();
              createGallery();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Gallery;
