import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getServicesAsync = createAsyncThunk(
  "services/getServicesAsync",
  async (data) => {
    const response = await axios.post(
      `/api/services/dashboard?pageNumber=${data.pageNumber}`,
      {
        sortState: data.state === undefined ? "" : data.state,
        catFilter:
          data.catFilter === undefined || data.catFilter === ""
            ? ""
            : data.catFilter,
        query: data.query === undefined || data.query === "" ? "" : data.query,
      }
    );

    if (response.status === 200) {
      const services = response.data.services;
      const pages = response.data.pages;
      const page = response.data.page;
      const count = response.data.page;
      return { services, pages, page, count };
    }
  }
);

export const addServiceAsync = createAsyncThunk(
  "services/addServiceAsync",
  async (data) => {
    const response = await axios.post("/api/services", data);
    if (response.status === 201) {
      const services = response.data.services;
      return { services };
    }
  }
);

export const getServiceDetailsAsync = createAsyncThunk(
  "services/getServicesDetailsAsync",
  async (id) => {
    const response = await axios.get(`/api/services/${id}`);

    if (response.status === 200) {
      const service = response.data;
      return { service };
    }
  }
);

export const deleteServiceAsync = createAsyncThunk(
  "services/deleteServiceAsync",
  async (id) => {
    const response = await axios.delete(`/api/services/${id}`);

    if (response.status === 200) {
      const service = response.data;
      return { service };
    }
  }
);

export const updateServiceAsync = createAsyncThunk(
  "services/updateServiceAsync",
  async (form) => {
    const { id, data } = form;
    const response = await axios.put(`/api/services/${id}`, data);
    if (response.status === 200) {
      const updatedService = response.data;
      return { updatedService };
    }
  }
);

export const toggleServiceFeaturedAsync = createAsyncThunk(
  "services/toggleServiceFeaturedAsync",
  async (id) => {
    const response = await axios.put(`/api/services/togglefeatured/${id}`, {});
    if (response.status === 200) {
      const togF = response.data;
      return { togF };
    }
  }
);

export const toggleServiceToDisplayAsync = createAsyncThunk(
  "services/toggleServiceToDisplayAsync",
  async (id) => {
    const response = await axios.put(`/api/services/toggletodisplay/${id}`, {});
    if (response.status === 200) {
      const toggle = response.data;
      return { toggle };
    }
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    service: null,
    loading: false,
    pages: 1,
    page: 1,
    count: 0,
    detailsLoading: false,
    updateSuccess: false,
    toggleSuccess: false,
    deleteSuccess: false,
  },
  reducers: {
    resetService: (state) => {
      state.toggleSuccess = false;
      state.updateSuccess = false;
      state.deleteSuccess = false;
    },

    sortServiceLtoH: (state) => {
      state.services = state.services.sort((a, b) =>
        a.stock > b.stock ? 1 : -1
      );
    },

    sortServiceHtoL: (state) => {
      state.services = state.services.sort((a, b) =>
        a.stock < b.stock ? 1 : -1
      );
    },
  },
  extraReducers: {
    [getServicesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getServicesAsync.fulfilled]: (state, action) => {
      state.services = action.payload.services;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
      state.count = action.payload.count;
      state.loading = false;
    },

    [addServiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addServiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getServiceDetailsAsync.pending]: (state, action) => {
      state.detailsLoading = true;
      state.serviceDetailsSuccess = false;
    },
    [getServiceDetailsAsync.fulfilled]: (state, action) => {
      state.serviceDetailsSuccess = true;
      state.service = action.payload.service;
      state.detailsLoading = false;
    },
    [updateServiceAsync.pending]: (state, action) => {
      state.updateSuccess = true;
    },
    [updateServiceAsync.fulfilled]: (state, action) => {
      state.updateSuccess = false;
    },
    [toggleServiceFeaturedAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [toggleServiceToDisplayAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [deleteServiceAsync.fulfilled]: (state, action) => {
      state.deleteSuccess = true;
    },
  },
});

export const { resetService, sortServiceLtoH, sortServiceHtoL } =
  servicesSlice.actions;

export default servicesSlice.reducer;
