import React from 'react';
import { Route, Routes } from 'react-router-dom';

//components

//pages

import DashBoard from '../Dashboard';
import AppointmentDetails from '../AppointmentDetails';
import AppointmentHistory from '../AppointmentHistory';
import ProductCategory from '../ProductCategory';
import AddProduct from '../AddProduct';
import Products from '../Products';
import Slider from '../Slider';
import Register from '../Register';
// import Reviews from "../Reviews";
import Login from '../Login';
import axios from 'axios';
import EditProduct from '../EditProduct';
import Enquiries from '../Enquiries';
import RequireAuth from '../../security/RequireAuth';
import ServiceCategory from '../ServiceCategory';
import AddService from '../AddService';
import Services from '../Services';
import EditService from '../EditService';
import Testimonial from '../Testimonial';
import Gallery from '../Gallery';
import WebData from '../WebData';
import AppointmentTiming from '../AppointmentTimings';
import ProductEnquiries from '../ProductEnquiries';
import Appointments from '../Appointments';
import Location from '../Location';

const App = () => {
  // axios.defaults.baseURL = 'https://backend.jaihoinstyle.com.au';
  axios.defaults.baseURL =
    process.env.NODE_ENV === 'production'
      ? 'https://backend.jaihoinstyle.com.au'
      : 'http://localhost:5000';

  return (
    <Routes>
      <Route path='/login' element={<Login />} />

      <Route path='/' element={<RequireAuth />}>
        <Route index element={<DashBoard />} />
        <Route path='appointment-timing' element={<AppointmentTiming />} />
        <Route path='appointments' element={<Appointments />} />
        <Route path='appointments/:pageNumber' element={<Appointments />} />
        <Route path='appointment-history' element={<AppointmentHistory />} />
        <Route
          path='appointment-history/:pageNumber'
          element={<AppointmentHistory />}
        />
        <Route
          path='appointment-list/appointment-details/:id'
          element={<AppointmentDetails />}
        />

        {/* TODO */}
        <Route path='service-category' element={<ServiceCategory />} />
        <Route path='add-service' element={<AddService />} />
        <Route path='edit-service/:id' element={<EditService />} />
        <Route path='services' element={<Services />} />
        <Route path='services/:pageNumber' element={<Services />} />

        <Route path='categories' element={<ProductCategory />} />
        <Route path='add-product' element={<AddProduct />} />
        <Route path='edit-product/:id' element={<EditProduct />} />
        <Route path='products' element={<Products />} />
        <Route path='products/:pageNumber' element={<Products />} />
        <Route path='product-enquiries' element={<ProductEnquiries />} />
        <Route
          path='product-enquiries/:pageNumber'
          element={<ProductEnquiries />}
        />

        <Route path='carousel' element={<Slider />} />
        <Route path='location' element={<Location />} />
        <Route path='testimonials' element={<Testimonial />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='web-data' element={<WebData />} />

        <Route path='register' element={<Register />} />
        <Route path='register/:pageNumber' element={<Register />} />

        <Route path='enquiries' element={<Enquiries />} />
        <Route path='enquiries/:pageNumber' element={<Enquiries />} />
      </Route>
    </Routes>
  );
};

export default App;
