import React, { useState, useEffect } from "react";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createLocationAsync,
  deleteLocationsAsync,
  getLocationAsync,
  resetLocation,
} from "../../redux/locationSlice";
import LoaderComp from "../../components/LoaderComp";

const Location = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const location = useSelector((state) => state.locations.location);
  const updateSuccess = useSelector((state) => state.locations.updateSuccess);
  const loading = useSelector((state) => state.locations.loading);

  //TESTIMONIAL STATES
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [invalidData, setInvalidData] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(resetLocation());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    dispatch(getLocationAsync());
  }, [dispatch]);

  const validateTestimonial = async () => {
    if (name.trim() === "" || address.trim() === "") {
      return false;
    }
    if (name && name.length >= 100) {
      return false;
    }
    return true;
  };

  const createTestimonial = async () => {
    const validate = await validateTestimonial();
    if (!validate) {
      setInvalidData(true);
    } else {
      setInvalidData(false);
      const data = {
        name,
        address,
      };
      dispatch(createLocationAsync(data));
      setName("");
      setAddress("");
      handleClose();
    }
  };

  if (loading) return <LoaderComp />;

  return (
    <div>
      <Container>
        <section>
          <h1>Location</h1>
        </section>

        <section className="SliderWrapper">
          <button className="bton bton--md bton--primary" onClick={handleShow}>
            Add Location
          </button>

          <Row>
            {location?.map((c, i) => {
              return (
                <Col md={4} key={i}>
                  <section className="testimonial">
                    <div className="testimonial-header">
                      <div className="testimonial-info">
                        <span className="testimonial-name">{c.name}</span>
                      </div>
                    </div>
                    <div className="testimonial-message">{c.address}</div>
                    <button
                      className="bton bton--danger"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteLocationsAsync(c._id));
                      }}
                    >
                      Delete
                    </button>
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {invalidData && name.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Name is required
                      </small>
                    )}
                    {invalidData && name.length > 100 && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Enter valid name (max 100 characters)
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {invalidData && address.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Address is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button className="bton bton--danger" onClick={handleClose}>
            Close
          </button>
          <button
            className="bton bton--primary"
            onClick={(e) => {
              e.preventDefault();
              createTestimonial();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Location;
