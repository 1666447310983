import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getServiceCategoriesAsync } from "../../redux/serviceCategorySlice";
import { addServiceAsync } from "../../redux/serviceSlice";

const AddService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(
    (state) => state.serviceCategory.serviceCategory
  );
  const [formField, setFormField] = useState({
    name: "",
    category: "",
    price: 0,
    isFeatured: false,
    toDisplay: false,
    limit: 0,
  });

  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getServiceCategoriesAsync());
  }, [dispatch]);

  const { name, category, price, limit } = formField;

  const onChange = (e) =>
    setFormField({ ...formField, [e.target.name]: e.target.value });

  const handleFeaturedChange = (e) =>
    setFormField({ ...formField, isFeatured: !formField.isFeatured });
  const handleToDisplayChange = (e) =>
    setFormField({ ...formField, toDisplay: !formField.toDisplay });

  const [validate, setValidate] = useState({
    name: false,
    category: false,
    price: false,
    limit: false,
    description: false,
  });

  const validateForm = async () => {
    var isValid = true;

    if (name.trim() === "") {
      setValidate((prev) => ({ ...prev, name: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, name: false }));
    }
    if (!category || category.trim() === "") {
      setValidate((prev) => ({ ...prev, category: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, category: false }));
    }
    if (isNaN(price) || Number(price) <= 0) {
      setValidate((prev) => ({ ...prev, price: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, price: false }));
    }
    if (isNaN(limit) || Number(limit) <= 0) {
      setValidate((prev) => ({ ...prev, limit: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, discount: false }));
    }

    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const isValid = await validateForm();

    if (isValid) {
      const data = {
        ...formField,
        description,
      };

      dispatch(addServiceAsync(data));

      navigate("/services");
    }
  };

  return (
    <>
      <Container>
        <section>
          <h1>Add Service</h1>
        </section>

        <Row>
          <Col md={12}>
            <section className="descProduct">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Service Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={onChange}
                        placeholder="Eg: Brazilian keratin treatment"
                      />
                      {validate.name && (
                        <small style={{ color: "red" }}>Name is required</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Limit</Form.Label>
                      <Form.Control
                        type="number"
                        name="limit"
                        min={1}
                        value={limit}
                        onChange={onChange}
                        placeholder="Set limit"
                      />
                      {validate.limit && (
                        <small style={{ color: "red" }}>
                          Limit must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={async (e) => {
                          setFormField({
                            ...formField,
                            category: e.target.value,
                          });
                        }}
                      >
                        {category === "" && (
                          <option value="">Select Category</option>
                        )}
                        {categories.map((c, i) => {
                          return (
                            <option key={i} value={c._id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validate.category && (
                        <small style={{ color: "red" }}>
                          Category is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Price </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={price}
                        onChange={onChange}
                        placeholder="set price"
                      />
                      {validate.price && (
                        <small style={{ color: "red" }}>
                          Price is required and must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Description</Form.Label>

                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                      />
                    </Form.Group>
                    {validate.description && (
                      <small style={{ color: "red" }}>
                        Description is required
                      </small>
                    )}
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox-1"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleFeaturedChange}
                        label="Featured"
                      />
                      <small className="text-disabled">
                        Will be shown in featured page
                      </small>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox-2"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleToDisplayChange}
                        label="Publish Service"
                      />
                      <small className="text-disabled">
                        Can be published later
                      </small>
                    </Form.Group>
                  </Col>
                </Row>

                <button
                  className="bton bton--primary bton--sm mt-4"
                  onClick={(e) => submitForm(e)}
                >
                  Add Service
                </button>
              </Form>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddService;
