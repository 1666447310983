import { Badge, Container, Form, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentHistoryAsync,
  getAppointmentHistoryFilterAsync,
} from "../../redux/appointmentSlice";
import moment from "moment";
import Paginate from "../../components/Paginate";
import LoaderComp from "../../components/LoaderComp";

const AppointmentHistory = () => {
  const [filterState, setFilterState] = useState("");
  const [filterStateDate, setFilterStateDate] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  let { appointmentsHistory, pagesHistory, pageHistory, isHistoryLoading } =
    useSelector((state) => state.appointments);

  const handleProductNavigation = (id) => {
    navigate(`/appointment-list/appointment-details/${id}`);
  };

  useEffect(() => {
    if (!filterState && !filterStateDate) {
      dispatch(getAppointmentHistoryAsync({ pageNumber }));
    } else {
      dispatch(
        getAppointmentHistoryFilterAsync({
          filterState,
          filterStateDate,
          pageNumber,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, filterStateDate, pageNumber]);

  if (isHistoryLoading) return <LoaderComp />;

  return (
    <>
      <Container>
        <section>
          <h1>Appointment History</h1>
        </section>

        <section className="OrderHistoryWrapper">
          <section className="filters">
            <div>
              <Form.Select
                aria-label="Default select example"
                value={filterState}
                onChange={(e) => {
                  e.target.value !== ""
                    ? setFilterState(e.target.value)
                    : setFilterState("");
                }}
              >
                <option value="">Status</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="CANCELLED">CANCELLED</option>
              </Form.Select>
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={filterStateDate}
                onChange={(e) => {
                  setFilterStateDate(e.target.value);
                }}
              >
                <option value="">SELECT</option>
                <option value="7">Last 7 days</option>
                <option value="30">This Month</option>
                <option value="365">This Year</option>
              </Form.Select>
            </div>
            <button
              className="bton bton--nacked bton--sm"
              onClick={() => {
                setFilterState("");
                setFilterStateDate("");
              }}
            >
              Clear Filter
            </button>
          </section>
        </section>

        <section>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Booked On</th>
                <th>Timing</th>
                <th>Status</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {appointmentsHistory?.map((appointment, i) => (
                <tr
                  key={appointment._id}
                  onClick={() => handleProductNavigation(appointment._id)}
                  className="cpntr"
                >
                  <td>{i + 1}</td>
                  <td>{appointment.fullname}</td>
                  <td>{appointment.email}</td>
                  <td>{appointment.phone}</td>
                  <td>{moment(appointment.createdAt).format("DD-MMM-YYYY")}</td>
                  {/* <td>{getFormattedPrice(appointment.subTotal)}</td> */}
                  <td>
                    <div className="flex flex-col">
                      <strong>
                        {moment(appointment.date).format("ddd, DD-MMM-YYYY")}
                      </strong>
                      <span>
                        Time: <strong>{appointment.time}</strong>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Badge bg="primary">
                        {appointment.status.replaceAll("_", " ")}
                      </Badge>
                    </div>
                  </td>
                  <td>
                    <section className="p-2 ">
                      <td>
                        <section className="p-2 ">
                          {appointment?.location || "-"}
                        </section>
                      </td>
                    </section>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Paginate
            pages={pagesHistory}
            page={pageHistory}
            isAdmin={true}
            list="appointment-history"
            navigate={navigate}
          />
        </section>
      </Container>
    </>
  );
};

export default AppointmentHistory;
