import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";
import {
  createServiceCategoryAsync,
  getServiceCategoriesAsync,
  resetService,
  resetServiceToggle,
  toggleServiceRemovedStatusAsync,
  updateServiceCategoryAsync,
} from "../../redux/serviceCategorySlice";

const ServiceCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.serviceCategory.serviceCategory
  );
  const loading = useSelector((state) => state.serviceCategory.loading);
  const updatingDone = useSelector(
    (state) => state.serviceCategory.updatingDone
  );
  const catUpdateDone = useSelector(
    (state) => state.serviceCategory.catUpdateDone
  );
  const toggleSuccess = useSelector(
    (state) => state.serviceCategory.toggleSuccess
  );

  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false);

  const [editingId, setEditingId] = useState("");

  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cateogryFieldsError, setCategoryFieldsError] = useState(false);

  useEffect(() => {
    dispatch(getServiceCategoriesAsync());
  }, [dispatch]);

  const checkFields = async () => {
    if (categoryName.trim() === "" || categoryImage.trim() === "") {
      setCategoryFieldsError(true);
      return true;
    } else {
      setCategoryFieldsError(false);
      return false;
    }
  };

  const createCategory = () => {
    const data = {
      name: categoryName,
      image: categoryImage,
    };
    dispatch(createServiceCategoryAsync(data));
  };

  const updateCategory = () => {
    const data = {
      id: editingId,
      name: categoryName,
      image: categoryImage,
    };
    dispatch(updateServiceCategoryAsync(data));
  };

  useEffect(() => {
    if (updatingDone) {
      dispatch(getServiceCategoriesAsync());
      dispatch(resetService());
    }
  }, [dispatch, updatingDone]);

  useEffect(() => {
    if (catUpdateDone) {
      dispatch(getServiceCategoriesAsync());
      dispatch(resetService());
    }
  }, [dispatch, catUpdateDone]);

  const toggle = (id) => {
    dispatch(toggleServiceRemovedStatusAsync(id));
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(getServiceCategoriesAsync());
      dispatch(resetServiceToggle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  const uploadCategoryImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setCategoryImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  if (loading) return <LoaderComp />;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Container>
        <section>
          <h1>Service Categories</h1>
        </section>

        <section className="ManCatWrapper">
          <button
            className="bton bton--sm bton--primary"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(false);
              setEditingId("");
              setCategoryName("");
              setCategoryImage("");
              handleShow();
            }}
          >
            Create Service Category
          </button>

          <Row>
            {categories &&
              categories.map((category, i) => {
                return (
                  <Col key={i} md={2}>
                    <section className="MainCatCard">
                      <figure className="MainCatCard-image">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${category.image}`}
                          alt=""
                        />
                        <figcaption>
                          <div className="MainCatCard-text">
                            {category.name}
                          </div>
                          <hr />
                          <div className="flexBetween mt-3">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => {
                                  toggle(category._id);
                                }}
                                checked={!category.removedStatus ? true : false}
                              />
                            </Form>

                            <div
                              className="cpntr"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setEditingId(category._id);
                                setCategoryName(category.name);
                                setCategoryImage(category.image);
                                setShow(true);
                              }}
                            >
                              Edit <i className="fa fa-edit ms-2 "></i>
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    </section>
                  </Col>
                );
              })}
          </Row>
        </section>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditing ? "Edit" : "Add"} Service Category
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {cateogryFieldsError && categoryName.trim() === "" && (
                  <small style={{ color: "red" }}>
                    Category Name is required
                  </small>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Category Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={uploadCategoryImageHandler}
                />
                {cateogryFieldsError && categoryImage.trim() === "" && (
                  <small style={{ color: "red" }}>Image is required</small>
                )}
              </Form.Group>

              {categoryImage !== "" && (
                <figure className="preview">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${categoryImage}`}
                    alt=""
                  />
                </figure>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={async (e) => {
                e.preventDefault();
                const isValid = await checkFields();

                if (!isValid) {
                  if (isEditing) {
                    updateCategory();
                  } else {
                    createCategory();
                  }
                  handleClose();
                }
              }}
              className="bton bton--sm bton--primary"
              disabled={uploading}
            >
              {!isEditing ? "Add" : "Edit"}
            </button>
            <button
              className="bton bton--sm bton--ghost--danger"
              onClick={handleClose}
            >
              cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ServiceCategory;
