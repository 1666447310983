import axios from "axios";
import React, { useState, useEffect } from "react";

import { Form, Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import Paginate from "../../components/Paginate";
import moment from "moment";
import { getFormattedPrice } from "../../utils/getFormattedPrice";

const ProductEnquiries = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [enquiries, setEnquiries] = useState(null);

  const getEnquiryList = async () => {
    setLoading(true);
    const response = await axios.get(`/api/enquiry?pageNumber=${pageNumber}`);
    if (response.status === 200) {
      setEnquiries(response?.data?.enquiry);

      setLoading(false);
    }
  };

  const changeStatus = async ({ id, status }) => {
    try {
      setLoading(true);
      const { data } = await axios.put(`/api/enquiry/status/${id}`, { status });
      setEnquiries((prev) =>
        prev.map((entry) =>
          entry._id === data._id ? { ...entry, status: status } : entry
        )
      );
      setLoading(false);
    } catch (err) {
      //DO NOTHING
    }
  };

  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  useEffect(() => {
    getEnquiryList({ pageNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  if (loading) return <LoaderComp />;

  return (
    <>
      <Container>
        <section>
          <h1>Product Enquiries</h1>
        </section>

        <section className="transactionWrapper">
          <section className="transTable">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Date</th>
                  <th>Message</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {enquiries?.map((i, key) => (
                  <tr key={key}>
                    <td>
                      <section className="enquiry-product">
                        <figure className="enquiry-product-image">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PREFIX}${i?.product?.heroImage}`}
                            alt=""
                          />
                        </figure>
                        <div className="enquiry-product-det">
                          <div>
                            <strong>{i?.product?.name}</strong>
                          </div>
                          <div>
                            Price: {getFormattedPrice(i?.product?.price)}
                          </div>
                          <div>Quantity: {i.quantity}</div>
                          <div>
                            Total:{" "}
                            {getFormattedPrice(i.quantity * i?.product?.price)}
                          </div>
                        </div>
                      </section>
                    </td>
                    <td>{i.fullname}</td>
                    <td>{i.email}</td>
                    <td>{i.phone}</td>
                    <td>{moment(i.createdAt).format("L")}</td>
                    <td>{i.message}</td>
                    <td>
                      <Form.Select
                        onChange={(e) => {
                          changeStatus({ status: e.target.value, id: i._id });
                        }}
                        value={i.status}
                      >
                        <option value="PLACED">Enquired</option>
                        <option value="PROCESSING">Processing</option>
                        <option value="COMPLETED">Resolved</option>
                        <option value="CANCELLED">Cancelled</option>
                      </Form.Select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!loading && (
              <Paginate
                pages={enquiries.pages}
                page={enquiries.page}
                isAdmin={true}
                list="product-enquiries"
                navigate={navigate}
              />
            )}
          </section>
        </section>
      </Container>
    </>
  );
};

export default ProductEnquiries;
