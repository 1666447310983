import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getGalleryAsync = createAsyncThunk(
  "gallery/getGalleryAsync",
  async () => {
    const response = await axios.get("/api/static/gallery");

    if (response.status === 200) {
      const gallery = response.data.gallery;
      return { gallery };
    }
  }
);

export const createGalleryAsync = createAsyncThunk(
  "gallery/createGalleryAsync",
  async (data) => {
    const response = await axios.post("/api/static/gallery", data);

    if (response.status === 201) {
      const gallery = response.data;
      return { gallery };
    }
  }
);

export const deleteGalleryAsync = createAsyncThunk(
  "gallery/deleteGalleryAsync",
  async (id) => {
    const response = await axios.delete(`/api/static/gallery/${id}`);

    if (response.status === 200) {
      return { id };
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    gallery: [],
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    resetGallery: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getGalleryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getGalleryAsync.fulfilled]: (state, action) => {
      state.gallery = action.payload.gallery;
      state.loading = false;
    },

    [createGalleryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createGalleryAsync.fulfilled]: (state, action) => {
      state.gallery = [...state.gallery, ...action.payload.gallery];
      state.loading = false;
    },
    [createGalleryAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteGalleryAsync.fulfilled]: (state, action) => {
      state.gallery = state.gallery.filter((c) => c._id !== action.payload.id);
    },
  },
});

export const { resetGallery } = gallerySlice.actions;

export default gallerySlice.reducer;
