import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLocationAsync = createAsyncThunk(
  "location/getLocationAsync",
  async () => {
    const response = await axios.get("/api/location");

    if (response.status === 200) {
      const locations = response.data;
      return { locations };
    }
  }
);

export const createLocationAsync = createAsyncThunk(
  "location/createLocationAsync",
  async (data) => {
    const response = await axios.post("/api/location", data);

    if (response.status === 201) {
      const location = response.data;
      return { location };
    }
  }
);

export const deleteLocationsAsync = createAsyncThunk(
  "location/deleteLocationsAsync",
  async (id) => {
    const response = await axios.delete(`/api/location/${id}`);

    if (response.status === 200) {
      return { id };
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState: {
    location: [],
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    resetLocation: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getLocationAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getLocationAsync.fulfilled]: (state, action) => {
      state.location = action.payload.locations;
      state.loading = false;
    },

    [createLocationAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createLocationAsync.fulfilled]: (state, action) => {
      state.location = [...state.location, action.payload.location];
      state.loading = false;
    },
    [createLocationAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteLocationsAsync.fulfilled]: (state, action) => {
      state.location = state.location.filter(
        (c) => c._id !== action.payload.id
      );
    },
  },
});

export const { resetLocation } = locationSlice.actions;

export default locationSlice.reducer;
