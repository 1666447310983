import React from "react";
import { Col, Form, ListGroup, Row } from "react-bootstrap";

const Card = ({
  title,
  data,
  handleAdd,
  handleRemove,
  error,
  text,
  allowedAppointents,
  setText,
  setAllowedAppointments,
}) => {
  return (
    <div className="appt-form_item">
      <div className="form-group appt-form_container">
        <div className="appt-form_lhead">
          <label className="appt-form_label" htmlFor="name">
            {title}
          </label>
        </div>
        <Form onSubmit={handleAdd} className="appt-form_indiv">
          <Row>
            <Col md={5}>
              <Form.Label>Time</Form.Label>
              <Form.Control
                name={title}
                className=" appt-form_input"
                placeholder="Eg. 10 AM"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Col>

            <Col md={5}>
              <Form.Label>Max. Appointments</Form.Label>
              <Form.Control
                name={title}
                className=" appt-form_input"
                placeholder="Eg: 5"
                value={allowedAppointents}
                type="number"
                onChange={(e) => {
                  setAllowedAppointments(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <button type="submit" className="bton bton--primary bton--sm">
                Add
              </button>
            </Col>
          </Row>
        </Form>
        {error && data?.length === 0 ? (
          <span className="input-error">Please provide atleast one timing</span>
        ) : null}
      </div>

      <ListGroup>
        {data.map((ele) => {
          return (
            <ListGroup.Item key={ele.timing}>
              <div className="flex-between">
                <span>
                  {ele.timing} - Max ({ele.allowedAppointments})
                </span>
                <i
                  className="fa fa-trash cda cpntr"
                  title="delete now"
                  onClick={() => handleRemove(ele.timing)}
                ></i>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default Card;
