import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppTimingAsync,
  updateAppTimingAsync,
} from "../../redux/webDataSlice";
import { Col, Container, Row } from "react-bootstrap";
import LoaderComp from "../../components/LoaderComp";
import Card from "./Card";

const AppointmentTiming = () => {
  const dispatch = useDispatch();
  const {
    apData: data,
    apLoading: loading,
    apUpdateLoading: updateLoading,
  } = useSelector((state) => state.webData);

  const [morning, setMorning] = useState([]);
  const [morningText, setMorningText] = useState("");
  const [morningAlTiming, setMorningAlTiming] = useState(0);

  const [afternoon, setAfternoon] = useState([]);
  const [afternoonText, setAfternoonText] = useState("");
  const [afternoonAlTiming, setAfternoonAlTiming] = useState(0);

  const [evening, setEvening] = useState([]);
  const [eveningText, setEveningText] = useState("");
  const [eveningAlTiming, setEveningAlTiming] = useState(0);

  const [error, setError] = useState(false);

  const handleUpdate = (e) => {
    e.preventDefault();
    let err = false;

    if (!morning || morning.length === 0) {
      err = true;
      setError(true);
    }

    if (!afternoon || afternoon.length === 0) {
      err = true;
      setError(true);
    }

    if (!evening || evening.length === 0) {
      err = true;
      setError(true);
    }

    if (!err) {
      const finalData = { morning, afternoon, evening };
      dispatch(updateAppTimingAsync(finalData));
    }
  };

  const handleCancel = () => {
    setMorning(data?.morning || []);
    setAfternoon(data?.afternoon || []);
    setEvening(data?.evening || []);
    setMorningText("");
    setAfternoonText("");
    setEveningText("");
  };

  const handleMorningAdd = (event) => {
    event.preventDefault();
    const alreadyExists = morning.find(
      (e) => e.timing.toLowerCase() === morningText.toLowerCase()
    );

    if (alreadyExists) {
      setMorningText("");
      setMorningAlTiming(0);
      return;
    }
    if (
      morningText &&
      morningText.trim() &&
      morningAlTiming &&
      !isNaN(morningAlTiming) &&
      morningAlTiming > 0
    ) {
      setMorning((prev) => [
        { timing: morningText.trim(), allowedAppointments: morningAlTiming },
        ...prev,
      ]);
      setMorningText("");
      setMorningAlTiming(0);
      setError(false);
    }
  };

  const handleMorningRemove = (value) => {
    setMorning((prev) => prev.filter((e) => e.timing !== value));
  };

  const handleAfternoonAdd = (event) => {
    event.preventDefault();
    const alreadyExists = afternoon.find(
      (e) => e.timing.toLowerCase() === afternoonText.toLowerCase()
    );

    if (alreadyExists) {
      setAfternoonText("");
      setAfternoonAlTiming(0);
      return;
    }
    if (
      afternoonText &&
      afternoonText.trim() &&
      afternoonAlTiming &&
      !isNaN(afternoonAlTiming) &&
      afternoonAlTiming > 0
    ) {
      setAfternoon((prev) => [
        {
          timing: afternoonText.trim(),
          allowedAppointments: afternoonAlTiming,
        },
        ...prev,
      ]);
      setAfternoonText("");
      setAfternoonAlTiming(0);
      setError(false);
    }
  };

  const handleAfternoonRemove = (value) => {
    setAfternoon((prev) => prev.filter((e) => e.timing !== value));
    setError(false);
  };

  const handleEveningAdd = (event) => {
    event.preventDefault();
    const alreadyExists = evening.find(
      (e) => e.timing.toLowerCase() === eveningText.toLowerCase()
    );

    if (alreadyExists) {
      setEveningText("");
      setEveningAlTiming(0);
      return;
    }
    if (
      eveningText &&
      eveningText.trim() &&
      eveningAlTiming &&
      !isNaN(eveningAlTiming) &&
      eveningAlTiming > 0
    ) {
      setEvening((prev) => [
        { timing: eveningText.trim(), allowedAppointments: eveningAlTiming },
        ...prev,
      ]);
      setEveningText("");
      setEveningAlTiming(0);
      setError(false);
    }
  };

  const handleEveningRemove = (value) => {
    setEvening((prev) => prev.filter((e) => e.timing !== value));
  };

  useEffect(() => {
    dispatch(getAppTimingAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setMorning(data?.morning);
      setAfternoon(data?.afternoon);
      setEvening(data?.evening);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <LoaderComp />;
  }

  return (
    <Container>
      <div className="appt">
        {updateLoading ? (
          <div className="page-loader">
            <LoaderComp />
          </div>
        ) : null}

        <div className="appt-header">
          <div className="appt-title">
            <h1>Appointment timing</h1>
            <div className="appt-btons">
              <button className="bton bton--sm" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="bton bton--sm bton--primary"
                onClick={handleUpdate}
              >
                Save
              </button>
            </div>
          </div>
          <span className="appt-desc">
            Provide the appointment timing information for various sessions
          </span>
        </div>

        <div className="appt-form">
          <Row>
            <Col md={6}>
              <Card
                title="Morning Session Timings"
                data={morning}
                handleAdd={handleMorningAdd}
                handleRemove={handleMorningRemove}
                text={morningText}
                setText={setMorningText}
                allowedAppointents={morningAlTiming}
                error={error}
                setAllowedAppointments={setMorningAlTiming}
              />
            </Col>
            <Col md={6}>
              <Card
                title="Afternoon Session Timings"
                data={afternoon}
                handleAdd={handleAfternoonAdd}
                handleRemove={handleAfternoonRemove}
                text={afternoonText}
                setText={setAfternoonText}
                allowedAppointents={afternoonAlTiming}
                error={error}
                setAllowedAppointments={setAfternoonAlTiming}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card
                title="Evening Session Timings"
                data={evening}
                handleAdd={handleEveningAdd}
                handleRemove={handleEveningRemove}
                text={eveningText}
                setText={setEveningText}
                allowedAppointents={eveningAlTiming}
                error={error}
                setAllowedAppointments={setEveningAlTiming}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default AppointmentTiming;
