import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function EnquiryStatus() {
  const [counts, setCounts] = useState(null);
  const { token } = useSelector((state) => state.users);

  const getOverview = async () => {
    try {
      const response = await axios.get("/api/enquiry/overview", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setCounts(response.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <Container>
        <Row>
          <h1>Product Enquiry Status</h1>
          <div className="text-muted">Your overall product enquiry status</div>
        </Row>

        <Row>
          <Col md={3}>
            <section className="statusCard bprt ">
              <div className="statusCard-status  cpr">Enquired</div>
              <div className="statusCard-number  cpr">{counts?.placed}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className="statusCard bset">
              <div className="statusCard-status  cse">Processing</div>
              <div className="statusCard-number  cse">{counts?.processing}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className="statusCard bsut">
              <div className="statusCard-status  csu">Resolved</div>
              <div className="statusCard-number  csu">{counts?.completed}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className="statusCard bdat ">
              <div className="statusCard-status  cda">Cancelled</div>
              <div className="statusCard-number  cda">{counts?.cancelled}</div>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default EnquiryStatus;
