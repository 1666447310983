import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserAsync } from "../../redux/userSlice";

const ModalComponent = ({
  message,
  action1,
  action2,
  button,
  classNAME,
  Title,
  id,
}) => {
  const [show, setShow] = useState(false);
  const { isDeleteSuccess } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUserAsync(id));
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    isDeleteSuccess && handleClose();
  }, [isDeleteSuccess]);

  return (
    <>
      <button className={`${classNAME}`} onClick={handleShow}>
        {button}
      </button>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>{Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="modalBody">
            <div className="modalBody-title mt-2 mb-5">{message}</div>
            <button
              className="bton bton--md bton--danger  me-2 ms-2"
              onClick={handleClose}
            >
              {action1}
            </button>
            <button
              className="bton bton--md bton--primary ms-3"
              onClick={handleDelete}
            >
              {action2}
            </button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
