import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import LoaderComp from "../../components/LoaderComp";
import { getWebDataAsync, updateWebDataAsync } from "../../redux/webDataSlice";
import { Col, Container, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const webDataSchema = Yup.object().shape({
  aboutHeader: Yup.string().trim().required("Required!"),
  galleryHeader: Yup.string().trim().required("Required!"),
  contactHeader: Yup.string().trim().required("Required!"),
  footerMessage: Yup.string().trim().required("Required!"),
  address: Yup.string().trim().required("Required!"),
  phone: Yup.string().trim().required("Required!"),
  email: Yup.string().trim().required("Required!"),
  maps: Yup.string().trim().url("Invalid link").nullable(),
  fbLink: Yup.string().trim().url("Invalid link").nullable(),
  instaLink: Yup.string().trim().url("Invalid link").nullable(),
  twitterLink: Yup.string().trim().url("Invalid link").nullable(),
  linkedInLink: Yup.string().trim().url("Invalid link").nullable(),
  mission: Yup.string().trim().required("Required!"),
  vision: Yup.string().trim().required("Required!"),
  aboutUs: Yup.string().trim().required("Required!"),
  openDaysText: Yup.string().trim().required("Required!"),
  timing: Yup.string().trim().required("Required!"),
});

function WebData() {
  const dispatch = useDispatch();
  const { data, loading, updateLoading } = useSelector(
    (state) => state.webData
  );
  const [aboutUsImage, setAboutUsImage] = useState("");
  const [aboutUsImageError, setAboutUsImageError] = useState("");

  const [missionImage, setMissionImage] = useState("");
  const [missionImageError, setMissionImageError] = useState("");

  const [visionImage, setVisionImage] = useState("");
  const [visionImageError, setVisionImageError] = useState("");

  const handleUpdate = (values) => {
    let err = false;

    if (!aboutUsImage) {
      setAboutUsImageError("Required!");
      err = true;
    }

    if (!missionImage) {
      setMissionImageError("Required!");
      err = true;
    }

    if (!visionImage) {
      setVisionImageError("Required!");
      err = true;
    }

    if (!err) {
      const finalData = { ...values, aboutUsImage, visionImage, missionImage };
      console.log(finalData);
      dispatch(updateWebDataAsync(finalData));
    }
  };

  const uploadAboutUsImage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setAboutUsImage(data);
      setAboutUsImageError("");
    } catch (error) {
      //console.error(error);
    }
  };

  const uploadMissionImage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setMissionImage(data);
      setMissionImageError("");
    } catch (error) {
      //console.error(error);
    }
  };

  const uploadVisionImage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setVisionImage(data);
      setVisionImageError("");
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getWebDataAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setAboutUsImage(data?.aboutUsImage);
      setMissionImage(data?.missionImage);
      setVisionImage(data?.visionImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <LoaderComp />;
  }

  return (
    <Container>
      <Formik
        onSubmit={handleUpdate}
        validationSchema={webDataSchema}
        initialValues={data}
      >
        {({ values, setFieldValue, errors, touched, resetForm }) => (
          <Form className="web">
            {updateLoading ? (
              <div className="page-loader">
                <LoaderComp />
              </div>
            ) : null}
            <div className="web-header">
              <div className="web-title">
                <h1>Static Website data</h1>
                <div className="web-btons">
                  <button className="bton bton--sm" onClick={() => resetForm()}>
                    Cancel
                  </button>
                  <button className="bton bton--sm bton--primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
              <span className="web-desc">
                Here you can find all the static content that are used in the
                website. You can update these data to keep your website updated.
              </span>
            </div>
            <div className="web-form">
              {/* ======================++++++++++ ======================= */}
              {/* ====================== ABOUT US ======================= */}
              {/* ====================== +++++++++ ======================= */}
              <div className="web-form_item">
                <div className="web-form_item--header">
                  <span className="web-form_item--title">
                    About Us Page Data
                  </span>
                </div>
                <div className="web-form_item--inputs">
                  <div className="form-group web-form_container">
                    <div className="web-form_lhead">
                      <label className="web-form_label" htmlFor="name">
                        About Header
                      </label>
                      <span className="web-form_desc">
                        About page hero section heading
                      </span>
                    </div>
                    <div className="web-form_indiv">
                      <Field
                        as="textarea"
                        rows="3"
                        name="aboutHeader"
                        className="form-control web-form_input"
                        placeholder="Eg. Elevating Beauty, Celebrating Culture"
                      />
                      {errors.aboutHeader && touched.aboutHeader ? (
                        <span className="input-error">
                          {errors.aboutHeader}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <Row>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            About Us
                          </label>
                          <span className="web-form_desc">
                            Enter about the shop in a short paragraph
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <ReactQuill
                            value={values?.aboutUs}
                            onChange={(e) => setFieldValue("aboutUs", e)}
                          />
                          {errors.aboutUs && touched.aboutUs ? (
                            <span className="input-error">
                              {errors.aboutUs}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            About Us Image
                          </label>
                          <span className="web-form_desc">
                            This image will be used along with about us text
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <input
                            type="file"
                            onChange={uploadAboutUsImage}
                            accept="image/*"
                          />
                          {aboutUsImageError && (
                            <span className="input-error">
                              {aboutUsImageError}
                            </span>
                          )}
                          {aboutUsImage !== "" && (
                            <figure className="web-form_img">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PREFIX}${aboutUsImage}`}
                                alt=""
                              />
                            </figure>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Mission
                          </label>
                          <span className="web-form_desc">
                            Enter about the mission of the shop
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <ReactQuill
                            value={values?.mission}
                            onChange={(e) => setFieldValue("mission", e)}
                          />
                          {errors.mission && touched.mission ? (
                            <span className="input-error">
                              {errors.mission}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Mission Image
                          </label>
                          <span className="web-form_desc">
                            This image will be used along with mission text
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <input
                            type="file"
                            onChange={uploadMissionImage}
                            accept="image/*"
                          />
                          {missionImageError && (
                            <span className="input-error">
                              {missionImageError}
                            </span>
                          )}
                          {missionImage !== "" && (
                            <figure className="web-form_img">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PREFIX}${missionImage}`}
                                alt=""
                              />
                            </figure>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Vision
                          </label>
                          <span className="web-form_desc">
                            Enter about the mission of the shop
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <ReactQuill
                            value={values?.vision}
                            onChange={(e) => setFieldValue("vision", e)}
                          />
                          {errors.vision && touched.vision ? (
                            <span className="input-error">{errors.vision}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Vision Image
                          </label>
                          <span className="web-form_desc">
                            This image will be used along with vision text
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <input
                            type="file"
                            onChange={uploadVisionImage}
                            accept="image/*"
                          />
                          {visionImageError && (
                            <span className="input-error">
                              {visionImageError}
                            </span>
                          )}
                          {visionImage !== "" && (
                            <figure className="web-form_img">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PREFIX}${visionImage}`}
                                alt=""
                              />
                            </figure>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* ======================++++++++++ ======================= */}
              {/* ====================== GALLERY ======================= */}
              {/* ====================== +++++++++ ======================= */}
              <div className="web-form_item">
                <div className="web-form_item--header">
                  <span className="web-form_item--title">
                    Gallery Page Data
                  </span>
                </div>
                <div className="web-form_item--inputs">
                  <div className="form-group web-form_container">
                    <div className="web-form_lhead">
                      <label className="web-form_label" htmlFor="name">
                        Gallery Header
                      </label>
                      <span className="web-form_desc">
                        Gallery page hero section heading
                      </span>
                    </div>
                    <div className="web-form_indiv">
                      <Field
                        as="textarea"
                        rows="3"
                        name="galleryHeader"
                        className="form-control web-form_input"
                        placeholder="Eg. Capturing moments of beauty in every style.  "
                      />
                      {errors.galleryHeader && touched.galleryHeader ? (
                        <span className="input-error">
                          {errors.galleryHeader}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* ======================++++++++++ ======================= */}
              {/* ====================== CONTACT ======================= */}
              {/* ====================== +++++++++ ======================= */}
              <div className="web-form_item">
                <div className="web-form_item--header">
                  <span className="web-form_item--title">
                    Contact Us Page Data
                  </span>
                </div>
                <div className="web-form_item--inputs">
                  <div className="form-group web-form_container">
                    <div className="web-form_lhead">
                      <label className="web-form_label" htmlFor="name">
                        Contact Us Header
                      </label>
                      <span className="web-form_desc">
                        Contact Us page hero section heading
                      </span>
                    </div>
                    <div className="web-form_indiv">
                      <Field
                        as="textarea"
                        rows="3"
                        name="contactHeader"
                        className="form-control web-form_input"
                        placeholder="Eg. Get in Touch with Jai Ho Hair & Beauty"
                      />
                      {errors.contactHeader && touched.contactHeader ? (
                        <span className="input-error">
                          {errors.contactHeader}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <Row>
                    {/* <Col md={4}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Address
                          </label>
                          <span className="web-form_desc">
                            Enter the location of the shop
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="address"
                            className="form-control web-form_input"
                            placeholder="Eg. King Street, Rockdale, NSW 2216, Sydney, Australia"
                          />
                          {errors.address && touched.address ? (
                            <span className="input-error">
                              {errors.address}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col> */}
                    <Col md={4}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Phone Numbers
                          </label>
                          <span className="web-form_desc">
                            Enter the phone number of shop
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="phone"
                            className="form-control web-form_input"
                            placeholder="Eg. +61 0295678472, +61 029560000"
                          />
                          {errors.phone && touched.phone ? (
                            <span className="input-error">{errors.phone}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Email
                          </label>
                          <span className="web-form_desc">
                            Enter the email address
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="email"
                            className="form-control web-form_input"
                            placeholder="Eg. demo@example.com"
                          />
                          {errors.email && touched.email ? (
                            <span className="input-error">{errors.email}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Facebook Page (Optional)
                          </label>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="fbLink"
                            className="form-control web-form_input"
                            placeholder="Enter facebook link"
                          />
                          {errors.fbLink && touched.fbLink ? (
                            <span className="input-error">{errors.fbLink}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Instagram Page (Optional)
                          </label>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="instaLink"
                            className="form-control web-form_input"
                            placeholder="Enter instagram link"
                          />
                          {errors.instaLink && touched.instaLink ? (
                            <span className="input-error">
                              {errors.instaLink}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Twitter Page (Optional)
                          </label>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="twitterLink"
                            className="form-control web-form_input"
                            placeholder="Enter twitter link"
                          />
                          {errors.twitterLink && touched.twitterLink ? (
                            <span className="input-error">
                              {errors.twitterLink}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            LinkedIn Page (Optional)
                          </label>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="linkedInLink"
                            className="form-control web-form_input"
                            placeholder="Enter linkedIn link"
                          />
                          {errors.linkedInLink && touched.linkedInLink ? (
                            <span className="input-error">
                              {errors.linkedInLink}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Maps Link (Optional)
                          </label>
                          <span className="web-form_desc">
                            To get the map link visit google maps and copy the
                            embed code and paste it here
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="maps"
                            className="form-control web-form_input"
                            placeholder="Enter map link"
                          />
                          {errors.maps && touched.maps ? (
                            <span className="input-error">{errors.maps}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* ======================++++++++++ ======================= */}
              {/* ====================== Footer ======================= */}
              {/* ====================== +++++++++ ======================= */}
              <div className="web-form_item">
                <div className="web-form_item--header">
                  <span className="web-form_item--title">Footer Data</span>
                </div>
                <div className="web-form_item--inputs">
                  <div className="form-group web-form_container">
                    <div className="web-form_lhead">
                      <label className="web-form_label" htmlFor="name">
                        Footer Message
                      </label>
                      <span className="web-form_desc">
                        Enter a short description of the shop
                      </span>
                    </div>
                    <div className="web-form_indiv">
                      <Field
                        as="textarea"
                        rows="5"
                        name="footerMessage"
                        className="form-control web-form_input"
                        placeholder="Eg. Elevating Beauty, Celebrating Culture - Where Style Meets Tradition at Jai Ho Hair & Beauty"
                      />
                      {errors.footerMessage && touched.footerMessage ? (
                        <span className="input-error">
                          {errors.footerMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <Row>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Open Days
                          </label>
                          <span className="web-form_desc">
                            Enter a line describing your open days
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="openDaysText"
                            className="form-control web-form_input"
                            placeholder="Eg. Open 7 days a week"
                          />
                          {errors.openDaysText && touched.openDaysText ? (
                            <span className="input-error">
                              {errors.openDaysText}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group web-form_container">
                        <div className="web-form_lhead">
                          <label className="web-form_label" htmlFor="name">
                            Timing
                          </label>
                          <span className="web-form_desc">
                            Enter timing of shop
                          </span>
                        </div>
                        <div className="web-form_indiv">
                          <Field
                            name="timing"
                            className="form-control web-form_input"
                            placeholder="Eg. 10:00 AM - 7:00 PM"
                          />
                          {errors.timing && touched.timing ? (
                            <span className="input-error">{errors.timing}</span>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default WebData;
