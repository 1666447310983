import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCarouselsAsync = createAsyncThunk(
  "carousel/getCarouselsAsync",
  async () => {
    const response = await axios.get("/api/carousel");

    if (response.status === 200) {
      const carousels = response.data.carousel;
      return { carousels };
    }
  }
);

export const createCarouselsAsync = createAsyncThunk(
  "carousel/createCarouselsAsync",
  async (data) => {
    const response = await axios.post("/api/carousel", data);

    if (response.status === 201) {
      const carousel = response.data;
      return { carousel };
    }
  }
);

export const deleteCarouselsAsync = createAsyncThunk(
  "carousel/deleteCarouselsAsync",
  async (id) => {
    const response = await axios.delete(`/api/carousel/${id}`);

    if (response.status === 200) {
      return { id };
    }
  }
);

const carouselSlice = createSlice({
  name: "carousel",
  initialState: {
    carousel: [],
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getCarouselsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = action.payload.carousels;
      state.loading = false;
    },

    [createCarouselsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = [...state.carousel, action.payload.carousel];
      state.loading = false;
    },
    [createCarouselsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = state.carousel.filter(
        (c) => c._id !== action.payload.id
      );
    },
  },
});

export const { reset } = carouselSlice.actions;

export default carouselSlice.reducer;
