import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getWebDataAsync = createAsyncThunk(
  "carousel/getWebDataAsync",
  async () => {
    const response = await axios.get("/api/static");

    if (response.status === 200) {
      return response.data;
    }
  }
);

export const updateWebDataAsync = createAsyncThunk(
  "carousel/updateWebDataAsync",
  async (data) => {
    const response = await axios.post("/api/static", data);

    if (response.status === 201) {
      return response.data;
    }
  }
);

export const getAppTimingAsync = createAsyncThunk(
  "carousel/getAppTimingAsync",
  async () => {
    const response = await axios.get("/api/static/app-timing");

    if (response.status === 200) {
      return response.data;
    }
  }
);

export const updateAppTimingAsync = createAsyncThunk(
  "carousel/updateAppTimingAsync",
  async (data) => {
    const response = await axios.post("/api/static/app-timing", data);

    if (response.status === 201) {
      return response.data;
    }
  }
);

const webDataSlice = createSlice({
  name: "webData",
  initialState: {
    data: null,
    apData: null,
    loading: false,
    updateLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getWebDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getWebDataAsync.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getWebDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateWebDataAsync.pending]: (state, action) => {
      state.updateLoading = true;
    },
    [updateWebDataAsync.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.updateLoading = false;
    },
    [updateWebDataAsync.rejected]: (state, action) => {
      state.updateLoading = false;
    },

    [getAppTimingAsync.pending]: (state, action) => {
      state.apLoading = true;
    },
    [getAppTimingAsync.fulfilled]: (state, action) => {
      state.apData = action.payload;
      state.apLoading = false;
    },
    [getAppTimingAsync.rejected]: (state, action) => {
      state.apLoading = false;
    },
    [updateAppTimingAsync.pending]: (state, action) => {
      state.apUpdateLoading = true;
    },
    [updateAppTimingAsync.fulfilled]: (state, action) => {
      state.apData = action.payload;
      state.apUpdateLoading = false;
    },
    [updateAppTimingAsync.rejected]: (state, action) => {
      state.apUpdateLoading = false;
    },
  },
});

export default webDataSlice.reducer;
