import axios from "axios";
import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Col, Container, Row, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchProductsDropdown from "../../components/SearchProductsDropdown";
import { getProductCategoriesAsync } from "../../redux/productCategorySlice";
import { addProductAsync } from "../../redux/productsSlice";
import LoaderComp from "../../components/LoaderComp";

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(
    (state) => state.productCategory.productCategory
  );
  const loading = useSelector((state) => state.products.loading);
  const [formField, setFormField] = useState({
    name: "",
    orderLimit: 0,
    isFeatured: false,
    category: "",
    heroImage: "",
    price: 0,
    maxPrice: 0,
    gallery: [],
    stock: 0,
    toDisplay: false,
    newArrival: false,
    related: [],
  });

  const [galleryImage, setGalleryImage] = useState([]);
  const [heroImg, setHeroImg] = useState("");
  const [description, setDescription] = useState("");

  const { name, category, orderLimit, price, maxPrice, stock } = formField;

  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);

  const onChange = (e) =>
    setFormField({ ...formField, [e.target.name]: e.target.value });

  const handleFeaturedChange = (e) =>
    setFormField({ ...formField, isFeatured: !formField.isFeatured });
  const handleToDisplayChange = (e) =>
    setFormField({ ...formField, toDisplay: !formField.toDisplay });

  const handleNewArrivalChange = (e) =>
    setFormField({ ...formField, newArrival: !formField.newArrival });

  const [validate, setValidate] = useState({
    name: false,
    category: false,
    price: false,
    maxPrice: false,
    orderLimit: false,
    stock: false,
    heroImage: false,
    description: false,
  });

  const validateForm = async () => {
    var isValid = true;

    if (name.trim() === "") {
      setValidate((prev) => ({ ...prev, name: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, name: false }));
    }
    if (!category || category.trim() === "") {
      setValidate((prev) => ({ ...prev, category: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, category: false }));
    }
    if (isNaN(price) || Number(price) <= 0) {
      setValidate((prev) => ({ ...prev, price: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, price: false }));
    }
    if (isNaN(maxPrice) || Number(maxPrice) < 0) {
      setValidate((prev) => ({ ...prev, maxPrice: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, maxPrice: false }));
    }

    if (description.trim() === "") {
      setValidate((prev) => ({ ...prev, description: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (heroImg.trim() === "") {
      setValidate((prev) => ({ ...prev, heroImage: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (isNaN(orderLimit) || Number(orderLimit) <= 0) {
      setValidate((prev) => ({ ...prev, orderLimit: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, orderLimit: false }));
    }
    if (isNaN(stock) || Number(stock) <= 0) {
      setValidate((prev) => ({ ...prev, stock: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, stock: false }));
    }

    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const isValid = await validateForm();

    if (isValid) {
      const productList_ = productList.map((e) => e._id);

      const data = {
        ...formField,
        description,
        heroImage: heroImg,
        gallery: galleryImage,
        related: productList_,
      };
      try {
        await dispatch(addProductAsync(data)).unwrap();

        navigate("/products");
      } catch (e) {}
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        await axios.get("/api/products/search").then((res) => {
          setProducts(res.data);
        });
      } catch (err) {
        // do nothing
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    dispatch(getProductCategoriesAsync());
  }, [dispatch]);

  const uploadHeroImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setHeroImg(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const uploadGalleryImageHandler = async (e) => {
    e.preventDefault();
    for (const file of e.target.files) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post("/api/upload", formData, config);
        setGalleryImage((prev) => [...prev, data]);
      } catch (error) {
        //console.error(error);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        <div className="page-loader">
          <LoaderComp />
        </div>
      ) : null}
      <Container>
        <section>
          <h1>Add Product</h1>
        </section>

        <Row>
          <Col md={4}>
            <section className=" galleryWrapper">
              <section>
                <h2 className="cse">Main Image</h2>
              </section>
              <section>
                <div className="center">
                  <label>
                    <i className="fa fa-cloud-upload"></i>
                    <br />
                    <span>Click to upload Image</span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={uploadHeroImageHandler}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </label>
                </div>
                {heroImg !== "" && (
                  <figure className="preview">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}${heroImg}`}
                      alt=""
                    />
                  </figure>
                )}
                {validate.heroImage && (
                  <small style={{ color: "red" }}>Hero Image is required</small>
                )}
              </section>
              <h2 className="cse mt-5">Gallery Images</h2>
              <div className="center">
                <label>
                  <i className="fa fa-cloud-upload"></i>
                  <br />
                  <span>Click to upload Image</span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={uploadGalleryImageHandler}
                    accept="image/png, image/jpg, image/jpeg"
                    multiple
                  />
                </label>
              </div>
              {galleryImage.length > 0 && (
                <Row>
                  {galleryImage &&
                    galleryImage.map((gi, i) => {
                      return (
                        <Col md={6} key={i}>
                          <section className="imageList">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PREFIX}${gi}`}
                              alt=""
                            />
                            <label>
                              <span
                                className="deleteIcon"
                                onClick={(e) => {
                                  e.preventDefault();

                                  const tempGalleryImage = galleryImage.filter(
                                    (d) => d.toString() !== gi.toString()
                                  );

                                  setGalleryImage(tempGalleryImage);
                                }}
                              >
                                <i className=" fa fa-trash cdngr"></i>
                              </span>
                            </label>
                          </section>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </section>{" "}
          </Col>
          <Col md={8}>
            <section className="descProduct">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={onChange}
                        placeholder="Enter product name"
                      />
                      {validate.name && (
                        <small style={{ color: "red" }}>Name is required</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Order Limit</Form.Label>
                      <Form.Control
                        type="number"
                        name="orderLimit"
                        min={1}
                        value={orderLimit}
                        onChange={onChange}
                        placeholder="set order limit"
                      />
                      {validate.orderLimit && (
                        <small style={{ color: "red" }}>
                          Order Limit must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={async (e) => {
                          setFormField({
                            ...formField,
                            category: e.target.value,
                          });
                        }}
                      >
                        {category === "" && (
                          <option value="">Select Category</option>
                        )}
                        {categories.map((c, i) => {
                          return (
                            <option key={i} value={c._id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validate.category && (
                        <small style={{ color: "red" }}>
                          Category is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Price </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={price}
                        onChange={onChange}
                        placeholder="set price"
                      />
                      {validate.price && (
                        <small style={{ color: "red" }}>
                          Price is required and must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>MRP</Form.Label>
                      <Form.Control
                        type="number"
                        name="maxPrice"
                        value={maxPrice}
                        onChange={onChange}
                        placeholder="Set a maximim price"
                      />
                      {validate.maPrice && (
                        <small style={{ color: "red" }}>
                          MRP shoud be non negative value
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Stock</Form.Label>
                      <Form.Control
                        type="number"
                        name="stock"
                        value={stock}
                        onChange={onChange}
                        placeholder="set stock"
                      />
                      {validate.stock && (
                        <small style={{ color: "red" }}>
                          Stock is required and must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Description</Form.Label>

                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                      />
                    </Form.Group>
                    {validate.description && (
                      <small style={{ color: "red" }}>
                        Description is required
                      </small>
                    )}
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox-1"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleFeaturedChange}
                        label="Featured"
                      />
                      <small>Will be shown in homepage</small>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox-3"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleNewArrivalChange}
                        label="New Arrival"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox-2"
                    >
                      <Form.Check
                        type="checkbox"
                        onChange={handleToDisplayChange}
                        label="Publish product"
                      />
                      <small className="text-disabled">
                        Can be published later
                      </small>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <h2>Related products</h2>
                      <SearchProductsDropdown
                        array={products}
                        placeholder={"Search Products"}
                        setProductList={setProductList}
                        productList={productList}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <ListGroup>
                      {productList.map((ele) => {
                        return (
                          <ListGroup.Item key={ele.name}>
                            {" "}
                            <div className="flex-between">
                              <span>{ele.name}</span>
                              <i
                                className="fa fa-trash cda cpntr"
                                title="delete now"
                                onClick={(e) => {
                                  e.preventDefault();

                                  setProductList([
                                    ...productList.filter(
                                      (pl) => pl._id !== ele._id
                                    ),
                                  ]);
                                }}
                              ></i>
                            </div>{" "}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>

                <button
                  className="bton bton--primary bton--sm mt-4"
                  onClick={(e) => submitForm(e)}
                  disabled={loading}
                >
                  Add Product
                </button>
              </Form>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddProduct;
