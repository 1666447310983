import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: null,
  isHistoryLoading: null,
  appointments: null,
  appointmentsHistory: null,
  isError: null,
  message: null,
  isSalesChartLoading: false,
  salesChart: [],
};

export const getAppointmentListAsync = createAsyncThunk(
  "user/getAppointmentListAsync",
  async (data, thunkApi) => {
    try {
      const response = await axios.get(
        `/api/appointment?pageNumber=${data?.pageNumber}`
      );
      const { appointments, pages, page } = response.data && response.data;
      return { appointments, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const filterWithStatusAsync = createAsyncThunk(
  "user/getAppointmentListAsync",
  async (data, thunkApi) => {
    try {
      const response = await axios.get(
        `/api/appointment/list/${data.status}?page=${data.pageNumber}`
      );
      const { appointments, pages, page } = response.data && response.data;
      return { appointments, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getAppointmentHistoryAsync = createAsyncThunk(
  "user/getAppointmentHistoryAsync",
  async (data, thunkApi) => {
    try {
      const response = await axios.get(
        `/api/appointment/history?pageNumber=${data.pageNumber}`
      );
      const { appointments, pages, page } = response.data && response.data;
      return { appointments, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getAppointmentHistoryFilterAsync = createAsyncThunk(
  "user/getAppointmentHistoryFilterAsync",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `/api/appointment/history/filter?pageNumber=${data.pageNumber}`,
        { status: data.filterState, date: data.filterStateDate }
      );
      const { appointments, pages, page } = response.data && response.data;
      return { appointments, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getSalesChartAsync = createAsyncThunk(
  "user/getSalesChartAsync",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`/api/appointment/sales-chart`);
      return res.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const appointmentslice = createSlice({
  name: "appointment",
  initialState,
  reducers: {},
  extraReducers: {
    [getAppointmentListAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAppointmentListAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.appointments = action.payload.appointments;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [filterWithStatusAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [filterWithStatusAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.appointments = action.payload.appointments;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [getAppointmentListAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.appointments = null;
    },
    [getAppointmentHistoryAsync.pending]: (state, action) => {
      state.isHistoryLoading = true;
    },
    [getAppointmentHistoryAsync.fulfilled]: (state, action) => {
      state.appointmentsHistory = action.payload.appointments;
      state.pagesHistory = action.payload.pages;
      state.pageHistory = action.payload.page;
      state.isHistoryLoading = false;
    },
    [getAppointmentHistoryAsync.rejected]: (state, action) => {
      state.isHistoryLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.appointments = null;
    },
    [getAppointmentHistoryFilterAsync.pending]: (state, action) => {
      state.isHistoryLoading = true;
    },
    [getAppointmentHistoryFilterAsync.fulfilled]: (state, action) => {
      state.appointmentsHistory = action.payload.appointments;
      state.pagesHistory = action.payload.pages;
      state.pageHistory = action.payload.page;
      state.isHistoryLoading = false;
    },
    [getAppointmentHistoryFilterAsync.rejected]: (state, action) => {
      state.isHistoryLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.appointments = null;
    },
    [getSalesChartAsync.pending]: (state, action) => {
      state.isSalesChartLoading = true;
    },
    [getSalesChartAsync.fulfilled]: (state, action) => {
      state.isSalesChartLoading = false;
      state.salesChart = action.payload;
    },
    [getSalesChartAsync.rejected]: (state, action) => {
      state.isSalesChartLoading = false;
      state.isError = true;
      state.message = action.payload.message;
    },
  },
});

export default appointmentslice.reducer;
