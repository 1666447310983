import React, { useEffect, useState } from "react";
import { Form, Container, Table } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import Paginate from "../../components/Paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import { getServiceCategoriesAsync } from "../../redux/serviceCategorySlice";
import {
  getServicesAsync,
  resetService,
  toggleServiceFeaturedAsync,
  toggleServiceToDisplayAsync,
} from "../../redux/serviceSlice";
import { getFormattedPrice } from "../../utils/getFormattedPrice";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;

  const services = useSelector((state) => state.services.services);
  const pages = useSelector((state) => state.services.pages);
  let page = useSelector((state) => state.services.page);
  const categories = useSelector(
    (state) => state.serviceCategory.serviceCategory
  );
  const toggleSuccess = useSelector((state) => state.services.toggleSuccess);

  const [sortState, setSortState] = useState("");
  const [catFilter, setCatFilter] = useState("");

  const [query, setQuery] = useState("");

  const loading = useSelector((state) => state.services.loading);

  const location = useLocation();

  const toggle = (totoggle, id) => {
    if (totoggle.toString() === "Featured") {
      dispatch(toggleServiceFeaturedAsync(id));
    } else if (totoggle.toString() === "Display") {
      dispatch(toggleServiceToDisplayAsync(id));
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getServicesAsync({ state: sortState, catFilter, query }));
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(
        getServicesAsync({ state: sortState, pageNumber, catFilter, query })
      );
      dispatch(resetService());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  useEffect(() => {
    dispatch(getServicesAsync({ state: sortState, catFilter, query }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, catFilter]);

  useEffect(() => {
    dispatch(getServicesAsync({ state: sortState, catFilter, query }));
  }, [dispatch, sortState]);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.updateSuccess === true) {
        dispatch(getServicesAsync({ pageNumber }));
        location.state.updateSuccess = false;
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    dispatch(getServicesAsync({ pageNumber, catFilter, query }));
    dispatch(getServiceCategoriesAsync());
  }, [dispatch, pageNumber]);

  return (
    <>
      <Container>
        <section>
          <h1>Services</h1>
        </section>

        <section className="ProductsWrapper">
          <div className="products-header">
            <form onSubmit={handleSearch} className="productSearch">
              <Form.Control
                type="search"
                name="source"
                className="productSearch-input"
                placeholder="Service Name..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button type="submit" className="productSearch-bton">
                Search
              </button>
            </form>
            <section className="productFilter">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setSortState(e.target.value);
                }}
              >
                <option>Sort By</option>
                <option value="lowToHigh">Price (High to Low)</option>
                <option value="highToLow">Price (Low to High)</option>
              </Form.Select>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setCatFilter(e.target.value);
                }}
              >
                <option value="">All Categories</option>
                {categories &&
                  categories.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </section>
          </div>
          <hr />
          {!loading ? (
            <section className="tableHolder">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Rate</th>
                    <th>Featured</th>
                    <th>Display</th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {services &&
                    services.map((service) => {
                      return (
                        <tr key={service._id}>
                          <td>
                            <section className="productList">
                              <div className="productList-name">
                                <div>
                                  <strong>{service.name}</strong>
                                </div>
                                <div>Category: {service.category.name}</div>
                              </div>
                            </section>
                          </td>
                          <td>
                            <span>{getFormattedPrice(service.price)}</span>
                          </td>

                          <td>
                            <section className="p-2 flex-between ">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onClick={() => {
                                    toggle("Featured", service._id);
                                  }}
                                  checked={service.isFeatured ? true : false}
                                  style={{ fontSize: "1rem" }}
                                  readOnly
                                />
                              </Form>
                            </section>
                          </td>
                          <td>
                            <section className="p-2 flex-between ">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onClick={() => {
                                    toggle("Display", service._id);
                                  }}
                                  checked={service.toDisplay ? true : false}
                                  style={{ fontSize: "1.2rem" }}
                                  readOnly
                                />
                              </Form>
                            </section>
                          </td>
                          <td>
                            <section className="p-2 flex-between ">
                              <Link to={`/edit-service/${service._id}`}>
                                <i
                                  className="fa fa-edit"
                                  style={{ fontSize: "1.2rem" }}
                                ></i>
                              </Link>
                            </section>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {services && (
                <Paginate
                  pages={pages}
                  page={page}
                  isAdmin={true}
                  list="services"
                  navigate={navigate}
                />
              )}
            </section>
          ) : (
            <LoaderComp />
          )}
        </section>
      </Container>
    </>
  );
};

export default Services;
