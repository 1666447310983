import React, { useState, useEffect } from "react";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createCarouselsAsync,
  deleteCarouselsAsync,
  getCarouselsAsync,
  reset,
} from "../../redux/carouselSlice";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";

const Slider = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const carousel = useSelector((state) => state.carousels.carousel);
  const updateSuccess = useSelector((state) => state.carousels.updateSuccess);
  const loading = useSelector((state) => state.carousels.loading);

  //CAROUSEL STATES
  const [carouselHeader, setCarouselHeader] = useState("");
  const [carouselImage, setCarouselImage] = useState("");

  const [validateCaru, setValidateCaru] = useState(false);

  const uploadCarouselImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setCarouselImage(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    dispatch(getCarouselsAsync());
  }, [dispatch]);

  const validateCraousel = async () => {
    if (carouselHeader.trim() === "" || carouselImage.trim() === "") {
      return false;
    } else {
      return true;
    }
  };

  const createCarousel = async () => {
    const validate = await validateCraousel();
    if (!validate) {
      setValidateCaru(true);
    } else {
      setValidateCaru(false);
      const data = {
        heading: carouselHeader,
        image: carouselImage,
      };
      dispatch(createCarouselsAsync(data));
      setCarouselHeader("");
      setCarouselImage("");
      handleClose();
    }
  };

  if (loading) return <LoaderComp />;

  return (
    <div>
      <Container>
        <section>
          <h1>Sliders</h1>
        </section>

        <section className="SliderWrapper">
          <button className="bton bton--md bton--primary" onClick={handleShow}>
            Add Slider
          </button>

          <Row>
            {carousel.map((c, i) => {
              return (
                <Col md={4} key={i}>
                  <section className="sliderHolder">
                    <figure className="sliderHolder-image">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PREFIX}${c.image}`}
                        alt=""
                      />
                    </figure>
                    <section className="linkCarousel">
                      <span>{c.heading}</span>
                    </section>
                    <button
                      className="bton bton--danger"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteCarouselsAsync(c._id));
                      }}
                    >
                      delete
                    </button>
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Slider </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Heading</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="The heading of the carousel"
                      value={carouselHeader}
                      onChange={(e) => setCarouselHeader(e.target.value)}
                    />
                    {validateCaru && carouselHeader.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Heading is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <section className="galleryWrapper">
                    <div className="center">
                      <input
                        type="text"
                        placeholder="Please Select Image"
                        style={{ display: "none" }}
                      />
                      <label>
                        <i className="fa fa-cloud-upload"></i>
                        <br />
                        <span>Click to upload Image</span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={uploadCarouselImageHandler}
                        />
                      </label>
                    </div>
                    {validateCaru && carouselImage.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Image is required
                      </small>
                    )}
                    {carouselImage && (
                      <figure className="preview">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${carouselImage}`}
                          alt=""
                        />
                      </figure>
                    )}
                  </section>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button className="bton bton--danger" onClick={handleClose}>
            Close
          </button>
          <button
            className="bton bton--primary"
            onClick={(e) => {
              e.preventDefault();
              createCarousel();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Slider;
