import axios from "axios";
import React from "react";
import moment from "moment";
import { Container, Form, Table } from "react-bootstrap";
import AppointmentStatus from "../../components/AppointmentStatus";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  filterWithStatusAsync,
  getAppointmentListAsync,
} from "../../redux/appointmentSlice";
import Paginate from "../../components/Paginate";
import Badge from "react-bootstrap/Badge";
import { useState } from "react";

const Appointments = () => {
  const [counts, setCounts] = useState(null);

  const getOverview = async () => {
    try {
      const response = await axios.get("/api/appointment/overview");
      if (response.status === 200) {
        setCounts(response.data);
      }
    } catch (e) {
      // do nothing
    }
  };

  const [status, setStatus] = useState("0");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  let { appointments, pages, page } = useSelector(
    (state) => state.appointments
  );
  const filterAppointmentHistory = (e) => {
    if (e.target.value === "0") {
      setStatus(null);
    } else {
      setStatus(e.target.value);
    }
  };
  useEffect(() => {
    if (status && status !== "0") {
      dispatch(filterWithStatusAsync({ status, pageNumber }));
    } else {
      dispatch(getAppointmentListAsync({ pageNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  useEffect(() => {
    if (status && status !== "0") {
      dispatch(filterWithStatusAsync({ status }));
    } else {
      dispatch(getAppointmentListAsync({ pageNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductNavigation = (id) => {
    navigate(`/appointment-list/appointment-details/${id}`);
  };

  return (
    <>
      {counts && <AppointmentStatus counts={counts} />}

      <section className="OrderPageWrapper">
        <Container>
          <section>
            <h2>Appointments</h2>

            <section className="text-muted">
              Manage all your appointments here
            </section>
          </section>

          <section className="flex-between actionBar">
            <Form.Select
              value={status}
              aria-label="Default select example"
              onChange={(e) => filterAppointmentHistory(e)}
            >
              <option value="0">All</option>
              <option value="PLACED">Booked</option>
              <option value="COMPLETED">Completed</option>
              <option value="CANCELLED">Cancelled</option>
            </Form.Select>
            <button
              className="bton bton--nacked bton--sm"
              onClick={() => setStatus("0")}
            >
              Clear Filter
            </button>
          </section>

          <section>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Booked On</th>
                  <th>Timing</th>
                  <th>Status</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {appointments?.map((appointment, i) => (
                  <tr
                    key={appointment._id}
                    onClick={() => handleProductNavigation(appointment._id)}
                    className="cpntr"
                  >
                    <td>{i + 1}</td>
                    <td>{appointment.fullname}</td>
                    <td>{appointment.email}</td>
                    <td>{appointment.phone}</td>
                    <td>
                      {moment(appointment.createdAt).format("DD-MMM-YYYY")}
                    </td>
                    {/* <td>{getFormattedPrice(appointment.subTotal)}</td> */}
                    <td>
                      <div className="flex flex-col">
                        <strong>
                          {moment(appointment.date).format("ddd, DD-MMM-YYYY")}
                        </strong>
                        <span>
                          Time: <strong>{appointment.time}</strong>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Badge bg="primary">
                          {appointment.status.replaceAll("_", " ")}
                        </Badge>
                      </div>
                    </td>
                    <td>
                      <section className="p-2 ">
                        {appointment?.location || "-"}
                      </section>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {appointments && (
              <Paginate
                pages={pages}
                page={page}
                isAdmin={true}
                list="appointments"
                navigate={navigate}
              />
            )}
          </section>
        </Container>
      </section>
    </>
  );
};

export default Appointments;
