import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  token: token ? token : null,
  user: null,
  isLoading: null,
  isError: null,
  users: null,
  isRegisterLoading: null,
  isRegisterSuccess: null,
  isUpdateSuccess: null,
  isDeleteSuccess: null,

  tokenStatus: "idle",
  tokenError: "",
};

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async ({ user, config }, thunkApi) => {
    try {
      const response = await axios.post("/api/users", user, config);
      return response.status === "201" && { success: true };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const editUserAsync = createAsyncThunk(
  "user/editUserAsync",
  async ({ user, config }, thunkApi) => {
    try {
      const response = await axios.put(
        `/api/users/${user._id.toString()}`,
        user,
        config
      );
      return response.status === "200" && { success: true };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const deleteUserAsync = createAsyncThunk(
  "user/deleteUserAsync",
  async (id, thunkApi) => {
    const { token } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(`/api/users/${id}`, config);
      return response.status === "200" && { success: true };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const userLoginAsync = createAsyncThunk(
  "user/userLoginAsync",
  async ({ email, password }, thunkApi) => {
    try {
      const response = await axios.post("/api/users/login", {
        email,
        password,
      });
      response?.data &&
        localStorage.setItem("token", JSON.stringify(response.data.token));
      const user = response?.data;
      return { user };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getUserListAsync = createAsyncThunk(
  "user/getUserListAsync",
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `/api/users?pageNumber=${data.pageNumber}`,
        config
      );
      const { users, pages, page } = response.data && response.data;
      return { users, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const loginWithToken = createAsyncThunk(
  "user/loginWithToken",
  async (_, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.get("/api/users/login-token");
      return { user: response?.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isLoading = null;
      state.isError = null;
      state.users = null;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isLoading = null;
      state.isError = null;
      state.users = null;
      localStorage.removeItem("token");
    },
    removeLoginWithTokenMessage: (state) => {
      state.tokenStatus = "idle";
      state.tokenError = "";
    },
  },
  extraReducers: {
    [userLoginAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [userLoginAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.token = action.payload.user.token;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.user.token}`;
    },
    [userLoginAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.user = null;
    },
    [registerUser.pending]: (state, action) => {
      state.isRegisterLoading = true;
      state.isRegisterSuccess = false;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.isRegisterLoading = false;
      state.isRegisterSuccess = true;
    },
    [registerUser.rejected]: (state, action) => {
      state.isRegisterLoading = false;
      state.isRegisterSuccess = false;
      state.isError = true;
    },
    [getUserListAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUserListAsync.fulfilled]: (state, action) => {
      state.isRegisterSuccess = null;
      state.isUpdateSuccess = null;
      state.isDeleteSuccess = null;
      state.isLoading = false;
      state.users = action.payload.users;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [getUserListAsync.rejected]: (state, action) => {
      state.isRegisterSuccess = null;
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.users = null;
    },
    [editUserAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editUserAsync.fulfilled]: (state, action) => {
      state.isUpdateSuccess = true;
      state.isLoading = false;
    },
    [editUserAsync.rejected]: (state, action) => {
      state.isUpdateSuccess = null;
      state.isError = true;
      state.message = action.payload.message;
    },
    [deleteUserAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteUserAsync.fulfilled]: (state, action) => {
      state.isDeleteSuccess = true;
      state.isLoading = false;
    },
    [deleteUserAsync.rejected]: (state, action) => {
      state.isDeleteSuccess = null;
      state.isError = true;
      state.message = action.payload.message;
    },
    [loginWithToken.pending]: (state, action) => {
      state.tokenStatus = "loading";
    },
    [loginWithToken.fulfilled]: (state, action) => {
      state.tokenStatus = "sucess";
      state.user = action.payload.user;
    },
    [loginWithToken.rejected]: (state, action) => {
      state.tokenStatus = "failed";
      state.tokenError = action.payload.message;
      state.token = null;
      state.user = null;
    },
  },
});
export const { reset, logout, removeLoginWithTokenMessage } = userSlice.actions;
export default userSlice.reducer;
