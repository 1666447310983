import React, { useState, useEffect } from "react";
import { Container, Modal, Row, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestimonialsAsync,
  deleteTestimonialsAsync,
  getTestimonialsAsync,
  resetTestomonial,
} from "../../redux/testimonialSlice";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";

const Testimonial = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const testimonial = useSelector((state) => state.testimonials.testimonial);
  const updateSuccess = useSelector(
    (state) => state.testimonials.updateSuccess
  );
  const loading = useSelector((state) => state.testimonials.loading);

  //TESTIMONIAL STATES
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [role, setRole] = useState("");
  const [testimonialImage, setTestimonialImage] = useState("");

  const [invalidData, setInvalidData] = useState(false);

  const uploadTestimonialImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setTestimonialImage(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(resetTestomonial());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    dispatch(getTestimonialsAsync());
  }, [dispatch]);

  const validateTestimonial = async () => {
    if (name.trim() === "" || message.trim() === "") {
      return false;
    }
    if (role && role.length >= 100) {
      return false;
    }
    if (name && name.length >= 100) {
      return false;
    }
    return true;
  };

  const createTestimonial = async () => {
    const validate = await validateTestimonial();
    if (!validate) {
      setInvalidData(true);
    } else {
      setInvalidData(false);
      const data = {
        name,
        image: testimonialImage,
        role,
        message,
      };
      dispatch(createTestimonialsAsync(data));
      setName("");
      setTestimonialImage("");
      setMessage("");
      setRole("");
      handleClose();
    }
  };

  if (loading) return <LoaderComp />;

  return (
    <div>
      <Container>
        <section>
          <h1>Testimonials</h1>
        </section>

        <section className="SliderWrapper">
          <button className="bton bton--md bton--primary" onClick={handleShow}>
            Add Testimonial
          </button>

          <Row>
            {testimonial.map((c, i) => {
              return (
                <Col md={4} key={i}>
                  <section className="testimonial">
                    <div className="testimonial-header">
                      <figure className="testimonial-image">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${c.image}`}
                          alt=""
                        />
                      </figure>
                      <div className="testimonial-info">
                        <span className="testimonial-name">{c.name}</span>
                        <span className="testimonial-role">{c.role}</span>
                      </div>
                    </div>
                    <div className="testimonial-message">{c.message}</div>
                    <button
                      className="bton bton--danger"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteTestimonialsAsync(c._id));
                      }}
                    >
                      Delete
                    </button>
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name of person"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {invalidData && name.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Name is required
                      </small>
                    )}
                    {invalidData && name.length > 100 && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Enter valid name (max 100 characters)
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Role/Organization (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Role/Organizaion of person"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                    {invalidData && role && role.length >= 100 && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Enter valid role (max 100 characters)
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {invalidData && message.trim() === "" && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Message is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <section className="galleryWrapper">
                    <span>Photo (Optional)</span>
                    <div className="center">
                      <label>
                        <i className="fa fa-cloud-upload"></i>
                        <br />
                        <span>Click to upload Picture</span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={uploadTestimonialImageHandler}
                        />
                      </label>
                    </div>
                    {testimonialImage && (
                      <figure className="preview">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${testimonialImage}`}
                          alt=""
                        />
                      </figure>
                    )}
                  </section>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button className="bton bton--danger" onClick={handleClose}>
            Close
          </button>
          <button
            className="bton bton--primary"
            onClick={(e) => {
              e.preventDefault();
              createTestimonial();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Testimonial;
