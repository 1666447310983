import React from "react";
import { Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userSlice";
const Sidebar = () => {
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  return (
    <div>
      <ul className="navList">
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/"
          >
            <i className="fa  fa-th-large"></i> &nbsp; Dashboard
          </NavLink>
        </li>
        <li className="navList-items">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <i className="fa  fa-tasks"></i>
                &nbsp; Appointments
              </Accordion.Header>
              <Accordion.Body>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/appointments"
                >
                  <i className="fa  fa-list"></i>
                  &nbsp; Appointments
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/appointment-timing"
                >
                  <i className="fa fa-bell"></i>
                  &nbsp; Timings
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/appointment-history"
                >
                  <i className="fa  fa-history"></i>
                  &nbsp; History
                </NavLink>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
        <li className="navList-items">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <i className="fa fa-paint-brush"></i> &nbsp; Service
              </Accordion.Header>
              <Accordion.Body>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/service-category"
                >
                  <i className="fa  fa-cubes"></i>&nbsp; Categories
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/add-service"
                >
                  <i className="fa fa-plus-square-o"></i>&nbsp; Add Service
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/services"
                >
                  <i className="fa fa-list-ul"></i>&nbsp; Service List
                </NavLink>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
        <li className="navList-items">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <i className="fa fa-cube"></i> &nbsp; Products
              </Accordion.Header>
              <Accordion.Body>
                <div className="navList-items  ">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "activeSidebar" : "notActive"
                    }
                    to="/product-enquiries"
                  >
                    <i className="fa fa-user-circle"></i> &nbsp; Enquiries
                  </NavLink>
                </div>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/categories"
                >
                  <i className="fa  fa-cubes"></i>&nbsp; Categories
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/add-product"
                >
                  <i className="fa fa-plus-square-o"></i>&nbsp; Add Product
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/products"
                >
                  <i className="fa fa-list-ul"></i>&nbsp; Product List
                </NavLink>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/carousel"
          >
            <i className="fa fa-image"></i> &nbsp; Carousel
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/testimonials"
          >
            <i className="fa fa-comments"></i> &nbsp; Testimonials
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/gallery"
          >
            <i className="fa fa-camera"></i> &nbsp; Gallery
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/web-data"
          >
            <i className="fa fa-database"></i> &nbsp; Website Data
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/location"
          >
            <i className="fa fa-map"></i> &nbsp; Branches
          </NavLink>
        </li>
        {user?.isSuperAdmin && (
          <li className="navList-items  ">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/register"
            >
              <i className="fa fa-user-plus"></i> &nbsp; Register
            </NavLink>
          </li>
        )}
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/enquiries"
          >
            <i className="fa fa-question-circle"></i> &nbsp; Enquiries
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            onClick={() => {
              dispatch(logout());
            }}
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/login"
          >
            <i className="fa fa-sign-out"></i> &nbsp; Logout
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
