import React from "react";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { user } = useSelector((state) => state.users);

  return (
    <section className="navBar">
      <ul className="navBox">
        <li className="navBox-items">
          <i className="fa  fa-user-circle"></i>&nbsp;
          <span className="adminName"> {user && user.name} </span>
        </li>
      </ul>
    </section>
  );
};

export default Navbar;
