import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTestimonialsAsync = createAsyncThunk(
  "testimonial/getTestimonialsAsync",
  async () => {
    const response = await axios.get("/api/testimonial");

    if (response.status === 200) {
      const testimonials = response.data.testimonials;
      return { testimonials };
    }
  }
);

export const createTestimonialsAsync = createAsyncThunk(
  "testimonial/createTestimonialsAsync",
  async (data) => {
    const response = await axios.post("/api/testimonial", data);

    if (response.status === 201) {
      const testimonial = response.data;
      return { testimonial };
    }
  }
);

export const deleteTestimonialsAsync = createAsyncThunk(
  "testimonial/deleteTestimonialsAsync",
  async (id) => {
    const response = await axios.delete(`/api/testimonial/${id}`);

    if (response.status === 200) {
      return { id };
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: {
    testimonial: [],
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    resetTestomonial: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getTestimonialsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getTestimonialsAsync.fulfilled]: (state, action) => {
      state.testimonial = action.payload.testimonials;
      state.loading = false;
    },

    [createTestimonialsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createTestimonialsAsync.fulfilled]: (state, action) => {
      state.testimonial = [...state.testimonial, action.payload.testimonial];
      state.loading = false;
    },
    [createTestimonialsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteTestimonialsAsync.fulfilled]: (state, action) => {
      state.testimonial = state.testimonial.filter(
        (c) => c._id !== action.payload.id
      );
    },
  },
});

export const { resetTestomonial } = testimonialSlice.actions;

export default testimonialSlice.reducer;
