import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./productsSlice";
import servicesReducer from "./serviceSlice";
import productCategoryReducer from "./productCategorySlice";
import serviceCategoryReducer from "./serviceCategorySlice";
import carouselReducer from "./carouselSlice";
import testimonialReducer from "./testimonialSlice";
import galleryReducer from "./gallerySlice";
import webDataReducer from "./webDataSlice";
import userReducer from "./userSlice";
import appointmentReducer from "./appointmentSlice";
import locationReducer from "./locationSlice";

export default configureStore({
  reducer: {
    products: productsReducer,
    services: servicesReducer,
    productCategory: productCategoryReducer,
    serviceCategory: serviceCategoryReducer,
    carousels: carouselReducer,
    testimonials: testimonialReducer,
    gallery: galleryReducer,
    webData: webDataReducer,
    users: userReducer,
    appointments: appointmentReducer,
    locations: locationReducer,
  },
});
