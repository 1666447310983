import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getServiceCategoriesAsync = createAsyncThunk(
  "serviceCategory/getServiceCategoriesAsync",
  async (_) => {
    const response = await axios.get("/api/categories/service");
    if (response.status === 200) {
      const categories = response.data.categories;
      return { categories };
    }
  }
);

export const createServiceCategoryAsync = createAsyncThunk(
  "serviceCategory/createServiceCategoryAsync",
  async (data) => {
    const response = await axios.post("/api/categories/service", data);

    if (response.status === 201) {
      const serviceCategory = response.data;
      return { serviceCategory };
    }
  }
);

export const updateServiceCategoryAsync = createAsyncThunk(
  "serviceCategory/updateServiceCategoryAsync",
  async (data) => {
    const response = await axios.put(
      `/api/categories/service/update/${data.id}`,
      data
    );

    if (response.status === 200) {
      const serviceCategory = response.data;
      return { serviceCategory };
    }
  }
);

export const toggleServiceRemovedStatusAsync = createAsyncThunk(
  "serviceCategory/toggleServiceRemovedStatusAsync",
  async (id) => {
    const response = await axios.put(
      `/api/categories/service/toggle/${id}`,
      {}
    );
    if (response.status === 200) {
      const togR = response.data;
      return { togR };
    }
  }
);

const serviceCategorySlice = createSlice({
  name: "serviceCategory",
  initialState: {
    serviceCategory: [],
    loading: false,
    updatingDone: false,
    catUpdateDone: false,
    isSuccess: false,
    toggleSuccess: false,
  },
  reducers: {
    resetService: (state) => {
      state.updatingDone = false;
      state.catUpdateDone = false;
    },
    resetServiceToggle: (state) => {
      state.toggleSuccess = false;
    },
  },
  extraReducers: {
    [getServiceCategoriesAsync.pending]: (state, action) => {
      state.loading = true;
      state.isError = false;
    },
    [getServiceCategoriesAsync.fulfilled]: (state, action) => {
      state.serviceCategory = action.payload.categories;
      state.loading = false;
      state.updatingDone = false;
    },

    [createServiceCategoryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createServiceCategoryAsync.fulfilled]: (state, action) => {
      state.serviceCategory = [
        ...state.serviceCategory,
        action.payload.serviceCategory,
      ];
      state.loading = false;
    },

    [updateServiceCategoryAsync.fulfilled]: (state, action) => {
      state.catUpdateDone = true;
    },
    [toggleServiceRemovedStatusAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
  },
});

export const { resetService, resetServiceToggle } =
  serviceCategorySlice.actions;

export default serviceCategorySlice.reducer;
