/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  ListGroup,
  CloseButton,
  Popover,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import SearchProductsDropdown from "../../components/SearchProductsDropdown";
import ReactQuill from "react-quill";

import { getProductCategoriesAsync } from "../../redux/productCategorySlice";
import {
  deleteProductAsync,
  getProductDetailsAsync,
  reset,
  updateProductAsync,
} from "../../redux/productsSlice";

const EditProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.productCategory.productCategory
  );
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.products.detailsLoading);
  const { deleteSuccess } = useSelector((state) => state.products);
  const updateSuccess = useSelector((state) => state.products.updateSuccess);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getProductDetailsAsync(id));
    }
  }, [dispatch, id]);

  const [heroImg, setHeroImg] = useState("");
  let [galleryImage, setGalleryImage] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const [show, setShow] = useState(false);

  const [formField, setFormField] = useState({
    name: "",
    orderLimit: 0,
    isFeatured: false,
    category: "",
    heroImage: "",
    price: 0,
    maxPrice: 0,
    gallery: [],
    stock: 0,
    toDisplay: false,
    newArrival: false,
    related: [],
  });
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!loading && product !== null) {
      setFormField({
        name: product.name,
        orderLimit: product.orderLimit,
        isFeatured: product.isFeatured,
        category: product.category._id,
        price: product.price,
        maxPrice: product.maxPrice,
        heroImage: product.heroImage,
        stock: product.stock,
        toDisplay: product.toDisplay,
        newArrival: product.newArrival,
        gallery: product.gallery,
        related: product.related,
      });
      setDescription(product.description);

      setProductList(product.related);

      setHeroImg(product.heroImage);

      setGalleryImage(product.gallery);
    }
  }, [loading, product]);

  useEffect(() => {
    dispatch(getProductCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await axios.get("/api/products/search").then((res) => {
          setProducts(res.data);
        });
      } catch (err) {
        // do nothing
      }
    };

    fetch();
  }, []);

  const {
    name,
    category,
    orderLimit,
    price,
    maxPrice,
    stock,
    isFeatured,
    toDisplay,
    newArrival,
  } = formField;

  const onChange = (e) =>
    setFormField({ ...formField, [e.target.name]: e.target.value });

  const handleFeaturedChange = (e) =>
    setFormField({ ...formField, isFeatured: !formField.isFeatured });
  const handleToDisplayChange = (e) =>
    setFormField({ ...formField, toDisplay: !formField.toDisplay });
  const handleNewArrivalChange = (e) =>
    setFormField({ ...formField, newArrival: !formField.newArrival });

  const [validate, setValidate] = useState({
    name: false,
    category: false,
    price: false,
    maxPrice: false,
    orderLimit: false,
    stock: false,
    heroImage: false,
    description: false,
  });

  const validateForm = async () => {
    var isValid = true;

    if (name.trim() === "") {
      setValidate((prev) => ({ ...prev, name: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, name: false }));
    }
    if (category.trim() === "") {
      setValidate((prev) => ({ ...prev, category: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, category: false }));
    }
    if (isNaN(price) || Number(price) <= 0) {
      setValidate((prev) => ({ ...prev, price: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, price: false }));
    }
    if (isNaN(maxPrice) || Number(maxPrice) < 0) {
      setValidate((prev) => ({ ...prev, maxPrice: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, maxPrice: false }));
    }

    if (description.trim() === "") {
      setValidate((prev) => ({ ...prev, description: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (heroImg.trim() === "") {
      setValidate((prev) => ({ ...prev, heroImage: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (isNaN(orderLimit) || Number(orderLimit) <= 0) {
      setValidate((prev) => ({ ...prev, orderLimit: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, orderLimit: false }));
    }
    if (isNaN(stock) || Number(stock) <= 0) {
      setValidate((prev) => ({ ...prev, stock: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, stock: false }));
    }

    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault(e);
    const isValid = await validateForm();

    if (isValid) {
      const productList_ = productList.map((e) => e._id);
      const data = {
        ...formField,
        description,
        heroImage: heroImg,
        gallery: galleryImage,
        related: productList_,
      };

      dispatch(updateProductAsync({ id: product._id, data }));
    }
  };
  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      navigate("/products", { state: { updateSuccess } });
    }
  }, [dispatch, navigate, updateSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(reset());
      navigate("/products");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleteSuccess]);

  const uploadHeroImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const { data } = await axios.post("/api/upload", formData);

      setHeroImg(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const uploadGalleryImageHandler = async (e) => {
    e.preventDefault();
    for (const file of e.target.files) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const { data } = await axios.post("/api/upload", formData);
        setGalleryImage((prev) => [...prev, data]);
      } catch (error) {
        //console.error(error);
      }
    }
  };

  const popover = (
    <Popover rootClose className="popover-over-modal" title="Delete payment">
      <section className="p-2 text-center">
        <div className="text-end mb-3">
          <CloseButton
            onClick={(e) => {
              e.preventDefault();

              setShow(false);
            }}
          ></CloseButton>
        </div>
        <h3>Delete this product?</h3>
        <button
          className="bton bton--ghost bton--sm"
          variant="default"
          onClick={(e) => {
            e.preventDefault();
            setShow(false);
          }}
        >
          Cancel
        </button>

        <button
          className="bton bton--sm bton--danger"
          variant="danger"
          onClick={(e) => {
            e.preventDefault();
            dispatch(deleteProductAsync(id));
          }}
        >
          Delete
        </button>
      </section>
    </Popover>
  );

  if (loading) return <LoaderComp />;

  return (
    <>
      <Container>
        <section>
          <h1>Edit Product</h1>
        </section>

        <Row>
          <Col md={4}>
            <section className=" galleryWrapper">
              <section>
                <h2 className="cse">Main Image</h2>
              </section>
              <section>
                <div className="center">
                  <label>
                    <i className="fa fa-cloud-upload"></i>
                    <br />
                    <span>Click to upload Image</span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={uploadHeroImageHandler}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </label>
                </div>
                {heroImg !== "" && (
                  <figure className="preview">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}${heroImg}`}
                      alt=""
                    />
                  </figure>
                )}
                {validate.heroImage && (
                  <small style={{ color: "red" }}>Hero Image is required</small>
                )}
              </section>
              <h2 className="cse mt-5">Gallery Images</h2>
              <div className="center">
                <label>
                  <i className="fa fa-cloud-upload"></i>
                  <br />
                  <span>Click to upload Image</span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={uploadGalleryImageHandler}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </label>
              </div>
              {galleryImage.length > 0 && (
                <Row>
                  {galleryImage.map((gi, i) => {
                    return (
                      <Col md={6} key={i}>
                        <section className="imageList">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PREFIX}${gi}`}
                            alt=""
                          />
                          <label>
                            <span
                              className="deleteIcon"
                              onClick={(e) => {
                                e.preventDefault();

                                galleryImage = galleryImage.filter(
                                  (d) => d.toString() !== gi.toString()
                                );

                                setGalleryImage(galleryImage);
                              }}
                            >
                              <i className=" fa fa-trash cdngr"></i>
                            </span>
                            <input type="file" style={{ display: "none" }} />
                          </label>
                        </section>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </section>
          </Col>
          <Col md={8}>
            <section className="descProduct">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={onChange}
                        placeholder="Enter product name"
                      />
                      {validate.name && (
                        <small style={{ color: "red" }}>Name is required</small>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Order Limit</Form.Label>
                      <Form.Control
                        type="number"
                        name="orderLimit"
                        value={orderLimit}
                        onChange={onChange}
                        min={1}
                        placeholder="set order limit"
                      />
                      {validate.orderLimit && (
                        <small style={{ color: "red" }}>
                          Order Limit must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={category}
                        onChange={async (e) => {
                          setFormField({
                            ...formField,
                            category: e.target.value,
                          });
                        }}
                      >
                        {category === "" && (
                          <option value="">Select Category</option>
                        )}
                        {categories.map((c, i) => {
                          return (
                            <option key={i} value={c._id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validate.category && (
                        <small style={{ color: "red" }}>
                          Category is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Price </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={price}
                        onChange={onChange}
                        placeholder="set price"
                      />
                      {validate.price && (
                        <small style={{ color: "red" }}>
                          Price is required and must greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>MRP</Form.Label>
                      <Form.Control
                        type="number"
                        name="maxPrice"
                        value={maxPrice}
                        onChange={onChange}
                        placeholder="Set a maximim price"
                      />
                      {validate.maPrice && (
                        <small style={{ color: "red" }}>
                          MRP shoud be non negative value
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Stock</Form.Label>
                      <Form.Control
                        type="number"
                        name="stock"
                        value={stock}
                        onChange={onChange}
                        placeholder="set stock"
                      />
                      {validate.stock && (
                        <small style={{ color: "red" }}>
                          Stock is required and must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Description</Form.Label>

                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                      />
                      {validate.description && (
                        <small style={{ color: "red" }}>
                          Description is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleFeaturedChange}
                        label="Featured"
                        checked={isFeatured === true ? true : false}
                      />
                      <small>Will be shown in homepage</small>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleNewArrivalChange}
                        label="New Arrival"
                        checked={newArrival === true ? true : false}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleToDisplayChange}
                        label="Publish Product"
                        checked={toDisplay === true ? true : false}
                      />
                      <small className="text-disabled">
                        Can be published later
                      </small>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <h2>Related products</h2>
                      <SearchProductsDropdown
                        array={products}
                        placeholder={"Search Products"}
                        setProductList={setProductList}
                        productList={productList}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <ListGroup>
                      {productList.map((ele, key) => {
                        return (
                          <ListGroup.Item key={key}>
                            <div className="flex-between">
                              <span>{ele.name}</span>
                              <i
                                className="fa fa-trash cda"
                                onClick={(e) => {
                                  e.preventDefault();

                                  setProductList([
                                    ...productList.filter(
                                      (pl) => pl._id !== ele._id
                                    ),
                                  ]);
                                }}
                              ></i>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>
                <div>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    show={show}
                    overlay={popover}
                  >
                    <button
                      className="bton bton--nacked bton--md cwa"
                      style={{ color: "red", textDecoration: "underline" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(true);
                      }}
                    >
                      Delete Product
                    </button>
                  </OverlayTrigger>
                </div>

                <button
                  className="bton bton--primary bton--sm mt-4"
                  onClick={(e) => submitForm(e)}
                >
                  Update
                </button>

                <Link to="/products">
                  <button className="bton bton--sm bton--ghost--danger ms-2">
                    Cancel
                  </button>
                </Link>
              </Form>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProduct;
